import { debug } from '@/../../../config';
export const TOAST_EVENT = 'toast';
export class Toast {
    static messageBus;
    static queue = [];
    static createToast(message, variant) {
        const toast = { message, variant };
        if (this.messageBus) {
            this.messageBus.$emit(TOAST_EVENT, toast);
        }
        else {
            this.queue.push(toast);
        }
    }
    static initialize(messageBus) {
        this.messageBus = messageBus;
        if (debug)
            console.info('Flushing toast queue', this.queue);
        this.queue.forEach((toast) => {
            messageBus.$emit(TOAST_EVENT, toast);
        });
        this.queue = [];
    }
}
