import { nanoid } from 'nanoid';
export var ReferenceType;
(function (ReferenceType) {
    ReferenceType["Unit"] = "unit";
    ReferenceType["Location"] = "location";
})(ReferenceType || (ReferenceType = {}));
export const createContainer = ({ name, content }) => {
    if (!name)
        throw new Error('Missing parameters');
    return {
        id: nanoid(),
        name,
        content: content || [],
    };
};
export const insertReference = ({ container, reference }) => {
    return {
        ...container,
        content: [
            ...container.content,
            reference,
        ],
    };
};
export const deleteReference = ({ container, target }) => {
    return {
        ...container,
        content: container.content.filter((ref) => ref.target != target),
    };
};
export const createReference = ({ name, type, target }) => {
    if (!(name || target))
        throw new Error('Missing parameters');
    return {
        name,
        type,
        target: target.toString(),
    };
};
export const deleteReferences = ({ record, target }) => {
    return Object.keys(record).reduce((acc, current) => {
        acc[current] = deleteReference({ container: record[current], target });
        return acc;
    }, {});
};
