/**
 * @overview Task data model
 */
import { nanoid } from 'nanoid';
import { EntityKind } from '@/core/models/dbEntity';
export const DEFAULT_TASK_NAME = 'New task';
export const DEFAULT_TASK_SIGNATURE = 'default';
const modelVersion = '2';
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Resting"] = "resting";
    TaskStatus["Working"] = "working";
    TaskStatus["Reported"] = "reported";
    TaskStatus["Done"] = "done";
})(TaskStatus || (TaskStatus = {}));
export var TaskType;
(function (TaskType) {
    TaskType["Custom"] = "custom";
    TaskType["Alarm"] = "alarm";
})(TaskType || (TaskType = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["Status"] = "status";
    ActivityType["Note"] = "note";
    ActivityType["Assignees"] = "assignees";
    ActivityType["Events"] = "events";
    ActivityType["Title"] = "title";
    ActivityType["Category"] = "category";
})(ActivityType || (ActivityType = {}));
const SYSTEM_USER = 'system';
/**
 * Create activity model
 */
export const createActivity = ({ type, note, user, status, title, category, assignees, eventIds, }) => {
    const activity = {
        date: new Date(),
        type,
    };
    activity['user'] = user || SYSTEM_USER;
    switch (type) {
        case ActivityType.Note: {
            if (!note)
                throw new Error('Missing note parameter');
            activity['note'] = note;
            break;
        }
        case ActivityType.Status: {
            if (!status)
                throw new Error('Missing status parameter');
            activity['status'] = status;
            break;
        }
        case ActivityType.Assignees: {
            if (!assignees)
                throw new Error('Missing assignees parameter');
            activity['assignees'] = assignees;
            break;
        }
        case ActivityType.Events: {
            if (!eventIds)
                throw new Error('Missing events parameter');
            activity['eventIds'] = eventIds;
            break;
        }
        case ActivityType.Category: {
            if (!category)
                throw new Error('Missing category parameter');
            activity['category'] = category;
            break;
        }
        case ActivityType.Title: {
            if (!title)
                throw new Error('Missing title parameter');
            activity['title'] = title;
            break;
        }
    }
    return activity;
};
/**
 * Create task model
 */
export const createTask = ({ type = TaskType.Custom, title = undefined, category = undefined, signature = DEFAULT_TASK_SIGNATURE, eventIds = [], }) => {
    const id = nanoid();
    const created = new Date();
    const assignees = [];
    const status = TaskStatus.Resting;
    const activities = [
        createActivity({ type: ActivityType.Status, status })
    ];
    return {
        id,
        created,
        version: modelVersion,
        kind: EntityKind.Task,
        title,
        category,
        type,
        signature,
        eventIds,
        assignees,
        status,
        activities,
    };
};
/**
 * Assign user to task
 */
export const assignUserToTask = (task, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    const assignees = [...task.assignees, user];
    return {
        ...task,
        assignees,
        activities: [...task.activities, createActivity({ type: ActivityType.Assignees, assignees, user })],
    };
};
/**
 * Withdraw user from task
 */
export const withdrawUserFromTask = (task, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    const index = task.assignees.indexOf(user);
    if (index > -1) {
        const assignees = task.assignees.slice(0, index).concat(task.assignees.slice(index + 1));
        return {
            ...task,
            assignees,
            activities: [...task.activities, createActivity({ type: ActivityType.Assignees, assignees, user })],
        };
    }
    else {
        throw new Error('User not assigned');
    }
};
/**
 * Update task note
 */
export const updateTaskNote = (task, note, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return {
        ...task,
        activities: [...task.activities, createActivity({ type: ActivityType.Note, note, user })],
    };
};
/**
 * Update task category
 */
export const updateTaskCategory = (task, category, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return {
        ...task,
        category,
        activities: [...task.activities, createActivity({ type: ActivityType.Category, category, user })],
    };
};
/**
 * Update task status
 */
export const updateTaskStatus = (task, status, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return {
        ...task,
        status,
        activities: [...task.activities, createActivity({ type: ActivityType.Status, status, user })],
    };
};
/**
 * Update task status
 */
export const updateTaskTitle = (task, title, user) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return {
        ...task,
        title,
        activities: [...task.activities, createActivity({ type: ActivityType.Title, title, user })],
    };
};
/**
 * Update associated task events
 */
export const updateTaskEvents = (task, eventIds) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return {
        ...task,
        eventIds,
        activities: [...task.activities, createActivity({ type: ActivityType.Events, eventIds })],
    };
};
/**
 * Add task events
 */
export const addTaskEvents = (task, eventIds) => {
    if (!task)
        throw new Error('Invalid task parameter');
    return updateTaskEvents(task, task.eventIds.concat(eventIds));
};
/**
 * Create signature for alarm task
 */
export const createAlarmSignature = (alarm) => {
    return `Alarm|${alarm.UnitID}`;
};
/**
 * Lookup referenced unit in alarm task
 */
export const alarmTaskUnit = (task) => {
    if (task.type !== TaskType.Alarm)
        return undefined;
    return Number(task.signature.match(/\d+$/g)?.join(''));
};
/**
 * Create alarm task from alarm API response
 */
export const createAlarmTask = ({ events, }) => {
    const alarm = events[0].alarm;
    const eventIds = events.map(event => event.id);
    const signature = createAlarmSignature(alarm);
    return createTask({ eventIds, type: TaskType.Alarm, signature });
};
/**
 * Validate task model
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateTask = (task) => {
    return !!task &&
        task.kind === EntityKind.Task &&
        !!task.created &&
        !!task.id && typeof task.id === 'string' &&
        !!task.type && typeof task.type === 'string' &&
        !!task.status && typeof task.status === 'string' &&
        !!task.activities && typeof task.activities === 'object';
};
/**
 * Validate task database record
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateTaskRecord = (data) => {
    if (!data)
        return false;
    if (typeof data !== 'object')
        return false;
    const task = Object.values(data)?.[0];
    return validateTask(task);
};
