const RESISTANCE_LEVEL_1 = 0;
// const RESISTANCE_LEVEL_2 = 1;
// const RESISTANCE_LEVEL_3 = 2;
// const RESISTANCE_LEVEL_4 = 3;
const LOOP_ERROR = 4;
// const LOOP_INTERUPT = 5;
const GALVANIC_LEVEL_1 = 6;
// const GALVANIC_LEVEL_2 = 7;
// const RADARPERCENT_LEVEL_1 = 8;
// const RADARPERCENT_LEVEL_2 = 9;
// const ANALOGA_LARM_LOW_1 = 10;
// const ANALOGA_LARM_HIGH_1 = 11;
// const ANALOGA_LARM_LOW_2 = 12;
// const ANALOGA_LARM_HIGH_2 = 13;
const ANALOGA_LARM_LOW_3 = 14;
// const ANALOGA_LARM_HIGH_3 = 15;
const ANALOGA_LARM_LOW_4 = 16;
// const ANALOGA_LARM_HIGH_4 = 17;
// const SCHEDULED_ALARM = 18;
const COMMUNICATION_ERROR = 19;
const PT_ALARM_LOW_1 = 20;
// const PT_ALARM_HIGH_1 = 21;
// const PT_ALARM_LOW2_ = 22;
// const PT_ALARM_HIGH_2 = 23;
// const PT_ALARM_LOW3_ = 24;
// const PT_ALARM_HIGH_3 = 25;
// const PT_ALARM_LOW4_ = 26;
// const PT_ALARM_HIGH_4 = 27;
const BATTERY_VOLTAGE_ALARM = 28;
const LEVEL_SWITCH_1 = 29;
const LEVEL_SWITCH_2 = 30;
// const LEVEL_SWITCH_3 = 31;
// const LEVEL_SWITCH_4 = 32;
const INTRUSION_ALARM = 34;
const FORMULA_1 = 39;
// const FORMULA_2 = 40;
// const FORMULA_3 = 41;
// const FORMULA_4 = 42;
const DISABLED = 43;
export const alarmTypes = [
    RESISTANCE_LEVEL_1,
    //  RESISTANCE_LEVEL_2,
    //  RESISTANCE_LEVEL_3,
    //  RESISTANCE_LEVEL_4,
    LOOP_ERROR,
    //  LOOP_INTERUPT,
    GALVANIC_LEVEL_1,
    //  GALVANIC_LEVEL_2,
    //  RADARPERCENT_LEVEL_1,
    //  RADARPERCENT_LEVEL_2,
    //  ANALOGA_LARM_LOW_1,
    //  ANALOGA_LARM_HIGH_1,
    //  ANALOGA_LARM_LOW_2,
    //  ANALOGA_LARM_HIGH_2,
    ANALOGA_LARM_LOW_3,
    //  ANALOGA_LARM_HIGH_3,
    ANALOGA_LARM_LOW_4,
    //  ANALOGA_LARM_HIGH_4,
    //  SCHEDULEDALARM,
    COMMUNICATION_ERROR,
    PT_ALARM_LOW_1,
    //  PT_ALARM_HIGH_1,
    //  PT_ALARM_LOW_2,
    //  PT_ALARM_HIGH_2,
    //  PT_ALARM_LOW_3,
    //  PT_ALARM_HIGH_3,
    //  PT_ALARM_LOW_4,
    //  PT_ALARM_HIGH_4,
    BATTERY_VOLTAGE_ALARM,
    LEVEL_SWITCH_1,
    LEVEL_SWITCH_2,
    //  LEVEL_SWITCH_3,
    //  LEVEL_SWITCH_4,
    INTRUSION_ALARM,
    FORMULA_1,
    //  FORMULA_2,
    //  FORMULA_3,
    //  FORMULA_4,
    DISABLED,
];
export var AlarmTypes;
(function (AlarmTypes) {
    AlarmTypes[AlarmTypes["RESISTANCE_LEVEL_1"] = 0] = "RESISTANCE_LEVEL_1";
    AlarmTypes[AlarmTypes["RESISTANCE_LEVEL_2"] = 1] = "RESISTANCE_LEVEL_2";
    AlarmTypes[AlarmTypes["RESISTANCE_LEVEL_3"] = 2] = "RESISTANCE_LEVEL_3";
    AlarmTypes[AlarmTypes["RESISTANCE_LEVEL_4"] = 3] = "RESISTANCE_LEVEL_4";
    AlarmTypes[AlarmTypes["LOOP_ERROR"] = 4] = "LOOP_ERROR";
    AlarmTypes[AlarmTypes["LOOP_INTERUPT"] = 5] = "LOOP_INTERUPT";
    AlarmTypes[AlarmTypes["GALVANIC_LEVEL_1"] = 6] = "GALVANIC_LEVEL_1";
    AlarmTypes[AlarmTypes["GALVANIC_LEVEL_2"] = 7] = "GALVANIC_LEVEL_2";
    AlarmTypes[AlarmTypes["RADARPERCENT_LEVEL_1"] = 8] = "RADARPERCENT_LEVEL_1";
    AlarmTypes[AlarmTypes["RADARPERCENT_LEVEL_2"] = 9] = "RADARPERCENT_LEVEL_2";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_LOW_10"] = 10] = "ANALOGA_LARM_LOW_10";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_HIGH_1"] = 11] = "ANALOGA_LARM_HIGH_1";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_LOW_2"] = 12] = "ANALOGA_LARM_LOW_2";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_HIGH_2"] = 13] = "ANALOGA_LARM_HIGH_2";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_LOW_3"] = 14] = "ANALOGA_LARM_LOW_3";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_HIGH_3"] = 15] = "ANALOGA_LARM_HIGH_3";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_LOW_4"] = 16] = "ANALOGA_LARM_LOW_4";
    AlarmTypes[AlarmTypes["ANALOGA_LARM_HIGH_4"] = 17] = "ANALOGA_LARM_HIGH_4";
    AlarmTypes[AlarmTypes["SCHEDULED_ALARM"] = 18] = "SCHEDULED_ALARM";
    AlarmTypes[AlarmTypes["COMMUNICATION_ERROR"] = 19] = "COMMUNICATION_ERROR";
    AlarmTypes[AlarmTypes["PT_ALARM_LOW_1"] = 20] = "PT_ALARM_LOW_1";
    AlarmTypes[AlarmTypes["PT_ALARM_HIGH_1"] = 21] = "PT_ALARM_HIGH_1";
    AlarmTypes[AlarmTypes["PT_ALARM_LOW2_2"] = 22] = "PT_ALARM_LOW2_2";
    AlarmTypes[AlarmTypes["PT_ALARM_HIGH_2"] = 23] = "PT_ALARM_HIGH_2";
    AlarmTypes[AlarmTypes["PT_ALARM_LOW3_4"] = 24] = "PT_ALARM_LOW3_4";
    AlarmTypes[AlarmTypes["PT_ALARM_HIGH_3"] = 25] = "PT_ALARM_HIGH_3";
    AlarmTypes[AlarmTypes["PT_ALARM_LOW4_6"] = 26] = "PT_ALARM_LOW4_6";
    AlarmTypes[AlarmTypes["PT_ALARM_HIGH_4"] = 27] = "PT_ALARM_HIGH_4";
    AlarmTypes[AlarmTypes["BATTERY_VOLTAGE_ALARM"] = 28] = "BATTERY_VOLTAGE_ALARM";
    AlarmTypes[AlarmTypes["LEVEL_SWITCH_1"] = 29] = "LEVEL_SWITCH_1";
    AlarmTypes[AlarmTypes["LEVEL_SWITCH_2"] = 30] = "LEVEL_SWITCH_2";
    AlarmTypes[AlarmTypes["LEVEL_SWITCH_3"] = 31] = "LEVEL_SWITCH_3";
    AlarmTypes[AlarmTypes["LEVEL_SWITCH_4"] = 32] = "LEVEL_SWITCH_4";
    AlarmTypes[AlarmTypes["INTRUSION_ALARM"] = 33] = "INTRUSION_ALARM";
    AlarmTypes[AlarmTypes["FORMULA_1"] = 34] = "FORMULA_1";
    AlarmTypes[AlarmTypes["FORMULA_2"] = 35] = "FORMULA_2";
    AlarmTypes[AlarmTypes["FORMULA_3"] = 36] = "FORMULA_3";
    AlarmTypes[AlarmTypes["FORMULA_4"] = 37] = "FORMULA_4";
    AlarmTypes[AlarmTypes["DISABLED"] = 38] = "DISABLED";
})(AlarmTypes || (AlarmTypes = {}));
export var ChartSeries;
(function (ChartSeries) {
    ChartSeries["DataAnalog1Value"] = "DataAnalog1Value";
    ChartSeries["DataAnalog2Value"] = "DataAnalog2Value";
    ChartSeries["DataAnalog3Value"] = "DataAnalog3Value";
    ChartSeries["DataAnalog4Value"] = "DataAnalog4Value";
    ChartSeries["DataCh1BatteryValue"] = "DataCh1BatteryValue";
    ChartSeries["DataCh2BatteryValue"] = "DataCh2BatteryValue";
    ChartSeries["DataCh3BatteryValue"] = "DataCh3BatteryValue";
    ChartSeries["DataCh4BatteryValue"] = "DataCh4BatteryValue";
    ChartSeries["DataCh1GalvanicResistance"] = "DataCh1GalvanicResistance";
    ChartSeries["DataCh2GalvanicResistance"] = "DataCh2GalvanicResistance";
    ChartSeries["DataCh3GalvanicResistance"] = "DataCh3GalvanicResistance";
    ChartSeries["DataCh4GalvanicResistance"] = "DataCh4GalvanicResistance";
    ChartSeries["DataCh1LoopResistance"] = "DataCh1LoopResistance";
    ChartSeries["DataCh2LoopResistance"] = "DataCh2LoopResistance";
    ChartSeries["DataCh3LoopResistance"] = "DataCh3LoopResistance";
    ChartSeries["DataCh4LoopResistance"] = "DataCh4LoopResistance";
    ChartSeries["DataCh1Resistance"] = "DataCh1Resistance";
    ChartSeries["DataCh2Resistance"] = "DataCh2Resistance";
    ChartSeries["DataCh3Resistance"] = "DataCh3Resistance";
    ChartSeries["DataCh4Resistance"] = "DataCh4Resistance";
    ChartSeries["DataBatteryVoltage"] = "DataBatteryVoltage";
    ChartSeries["DataBER"] = "DataBER";
    ChartSeries["DataLevelFormula1"] = "DataLevelFormula1";
    ChartSeries["DataLevelFormula2"] = "DataLevelFormula2";
    ChartSeries["DataLevelFormula3"] = "DataLevelFormula3";
    ChartSeries["DataLevelFormula4"] = "DataLevelFormula4";
    ChartSeries["DataInternalTemperature"] = "DataInternalTemperature";
    ChartSeries["DataIntrusion"] = "DataIntrusion";
    ChartSeries["DataLevelSwitch1"] = "DataLevelSwitch1";
    ChartSeries["DataLevelSwitch2"] = "DataLevelSwitch2";
    ChartSeries["DataLevelSwitch3"] = "DataLevelSwitch3";
    ChartSeries["DataLevelSwitch4"] = "DataLevelSwitch4";
    ChartSeries["DataPT1Value"] = "DataPT1Value";
    ChartSeries["DataPT2Value"] = "DataPT2Value";
    ChartSeries["DataPT3Value"] = "DataPT3Value";
    ChartSeries["DataPT4Value"] = "DataPT4Value";
    ChartSeries["DataSignalStrength"] = "DataSignalStrength";
})(ChartSeries || (ChartSeries = {}));
export var DateIntervalUnits;
(function (DateIntervalUnits) {
    DateIntervalUnits["Hour"] = "hour";
    DateIntervalUnits["Day"] = "day";
})(DateIntervalUnits || (DateIntervalUnits = {}));
export var UnitStatusNames;
(function (UnitStatusNames) {
    UnitStatusNames["Alarm"] = "alarm";
    UnitStatusNames["NotInstalled"] = "notInstalled";
    UnitStatusNames["Ok"] = "ok";
    UnitStatusNames["Problem"] = "problem";
})(UnitStatusNames || (UnitStatusNames = {}));
export var UnitColor;
(function (UnitColor) {
    UnitColor["alarm"] = "#C41539";
    UnitColor["notInstalled"] = "#FFFFFF";
    UnitColor["ok"] = "#4BD3AF";
    UnitColor["problem"] = "#ff9900";
})(UnitColor || (UnitColor = {}));
export const clusterIconSizes = [
    53,
    56,
    66,
    78,
    90
];
//Cluster colors based on their severity; index 0 has highest priority
export const clusterColors = [
    'red',
    'orange',
    'green'
];
export const unitUpdateIntegerFields = [
    'UNITTYPE',
    'UNITSCHEDULE',
    'UNITCONTINOUSREADING',
    'UNITGROUPID',
    'UNITPORT',
    'UNITCURRENTPORT',
    'UNITREPLYSOCKETPORT',
    'UNITREPLYSOCKETPORT2',
    'UNITCOMMUNICATIONALARMID',
    'UNITANALOGALARMRETRIGGERTIME',
    'UNITINTRUSIONSHOW',
    'UNITINTRUSIONON',
    'UNITINTRUSIONALARMID',
    'UNITPINCODE',
    'UNITX1GPRSLISTENINGPORT',
    'UNITX1SENSORDELAY',
    'UNITX1CONVERTERSTATUS',
    'UNITX1PINGINTERFACE',
    'UNITINTERNALTEMPERATURESHOW',
    'UNITSIGNALSTRENGTHSHOW',
    'UNITBERSHOW',
    'UNITBATTERYVOLTAGESHOW',
    'UNITBATTERYVOLTAGEALARM',
    'UNITBATTERYVOLTAGEALARMID',
    'UNITUPDATEATNEXTSCHEDULE',
    'UNITVALEVELSHOW',
    'UNITCATEGORY',
    'UNITSECTIONID',
    'UNITDISABLEDUNTIL',
    'UNITDISABLEDALARMID',
    'UNITMEASUREINTERVAL',
    'UNITWLANCHANNEL',
    'UNITACTIVEFTP',
    'UNITDISABLED',
    'UNITALARMACTIVE',
];
export const unitUpdateStringFields = [
    'UNITNAME',
    'UNITDESCRIPTION',
    'UNITLOCATION',
    'UNITCOMMENT',
    'UNITADRESS',
    'UNITCURRENTADRESS',
    'UNITCURRENTMASK',
    'UNITCURRENTGATEWAY',
    'UNITREPLYSOCKETADRESS',
    'UNITREPLYSOCKETADRESS2',
    'UNITMASKALARMCONNECTION',
    'UNITX1GPRSIP',
    'UNITX1GPRSFIREWALLIP',
    'UNITX1GPRSAPN',
    'UNITUPDATEATNEXTSCHEDULEPATH',
    'UNITTIMEZONE',
    'UNITWLANSSID',
    'UNITWLANPASSWORD',
    'UNITSMSNUMBER',
];
export const unitUpdateFloatFields = [
    'UNITGPSLONGITUDE',
    'UNITGPSLATITUDE',
];
export const languages = {
    44: 'en',
    45: 'da',
    46: 'sv',
    49: 'de',
    86: 'zh',
    966: 'ar',
};
export const channelSeries = [
    ChartSeries.DataCh1LoopResistance,
    ChartSeries.DataCh2LoopResistance,
    ChartSeries.DataCh3LoopResistance,
    ChartSeries.DataCh4LoopResistance,
];
export const analogSeries = [
    ChartSeries.DataAnalog1Value,
    ChartSeries.DataAnalog2Value,
    ChartSeries.DataAnalog3Value,
    ChartSeries.DataAnalog4Value,
];
export const ptSeries = [
    ChartSeries.DataPT1Value,
    ChartSeries.DataPT2Value,
    ChartSeries.DataPT3Value,
    ChartSeries.DataPT4Value,
];
export const channelLoopResistanceSeries = [
    ChartSeries.DataCh1LoopResistance,
    ChartSeries.DataCh2LoopResistance,
    ChartSeries.DataCh3LoopResistance,
    ChartSeries.DataCh4LoopResistance,
];
export const channelGalvanicSeries = [
    ChartSeries.DataCh1GalvanicResistance,
    ChartSeries.DataCh2GalvanicResistance,
    ChartSeries.DataCh3GalvanicResistance,
    ChartSeries.DataCh4GalvanicResistance,
];
export const switchSeries = [
    ChartSeries.DataLevelSwitch1,
    ChartSeries.DataLevelSwitch2,
    ChartSeries.DataLevelSwitch3,
    ChartSeries.DataLevelSwitch4,
];
export const formulaSeries = [
    ChartSeries.DataLevelFormula1,
    ChartSeries.DataLevelFormula2,
    ChartSeries.DataLevelFormula3,
    ChartSeries.DataLevelFormula4,
];
export const dateIntervals = [
    {
        quantity: 1,
        unit: DateIntervalUnits.Hour,
    },
    {
        quantity: 6,
        unit: DateIntervalUnits.Hour,
    },
    {
        quantity: 1,
        unit: DateIntervalUnits.Day,
    },
    {
        quantity: 3,
        unit: DateIntervalUnits.Day,
    },
    {
        quantity: 7,
        unit: DateIntervalUnits.Day,
    },
    {
        quantity: 30,
        unit: DateIntervalUnits.Day,
    },
    //GÖMA DESSA VAL FÖR V1
    // {
    //   quantity: 60,
    //   unit: DateIntervalUnits.Day,
    // },
    // {
    //   quantity: 180,
    //   unit: DateIntervalUnits.Day,
    // },
    // {
    //   quantity: 365,
    //   unit: DateIntervalUnits.Day,
    // },
];
// Priority order for temp values. The first is of highest priority
export const tempPriorityOrder = [
    'PT 1',
    'PT 2',
    'PT 3',
    'PT 4',
    'PT1000 1',
    'PT1000 2',
    'Temp',
    'chartdata.datainternaltemperature',
    'Int. temp.',
    'Int Temp',
    'Temp intern'
];
export const criticalAlarmTypes = [0, 30];
