import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/core/store';
Vue.use(VueRouter);
export const router = new VueRouter({
    routes: [
        {
            component: async () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
            name: 'login',
            path: '/login',
        },
        {
            path: '/',
            name: 'root',
            redirect: '/dashboard/overview',
            component: async () => import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
            children: [
                {
                    component: async () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
                    name: 'dashboard',
                    path: '/dashboard/:id',
                },
                {
                    component: async () => import(/* webpackChunkName: "notifications" */ '@/views/Notifications.vue'),
                    name: 'notifications',
                    path: '/notifications',
                },
                {
                    component: async () => import(/* webpackChunkName: "devicemanagement" */ '@/views/DeviceManagement.vue'),
                    name: 'devicemanagement',
                    path: '/devicemanagement',
                },
                {
                    component: async () => import(/* webpackChunkName: "groupmanagement" */ '@/views/GroupManagement.vue'),
                    name: 'groupmanagement',
                    path: '/groupmanagement',
                },
                {
                    component: async () => import(/* webpackChunkName: "locationmanagement" */ '@/views/LocationManagement.vue'),
                    name: 'locationmanagement',
                    path: '/locationmanagement',
                },
                {
                    component: async () => import(/* webpackChunkName: "taskmanagement" */ '@/views/TaskManagement.vue'),
                    name: 'taskmanagement',
                    path: '/taskmanagement',
                },
            ],
        },
    ],
});
router.beforeEach(async (to, from, next) => {
    const user = store.getters.user;
    const hasLoaded = store.getters['dashboard/hasLoaded'];
    if (to.name === 'login' && user) {
        next({ name: 'dashboard' });
    }
    else if (to.name === 'dashboard') {
        if (!user) {
            next({ name: 'login', query: { redirect: to.fullPath } });
        }
        else {
            if (!hasLoaded) {
                store.watch((state, getters) => getters['dashboard/hasLoaded'], () => {
                    next();
                });
            }
            else {
                next();
            }
        }
    }
    else if (to.name !== 'login' && !user) {
        next({ name: 'login' });
    }
    else {
        next();
    }
});
