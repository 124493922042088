export var AccessLevel;
(function (AccessLevel) {
    AccessLevel[AccessLevel["SuperUser"] = 0] = "SuperUser";
    AccessLevel[AccessLevel["User"] = 1] = "User";
    AccessLevel[AccessLevel["Reader"] = 2] = "Reader";
})(AccessLevel || (AccessLevel = {}));
export var Widget;
(function (Widget) {
    Widget[Widget["TyrensGIS"] = 1] = "TyrensGIS";
    Widget[Widget["AWG"] = 2] = "AWG";
    Widget[Widget["Parking"] = 3] = "Parking";
})(Widget || (Widget = {}));
