import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import dashboard from './modules/dashboard';
import container from './modules/container';
import setting from './modules/setting';
import eventManagement from './modules/event-management';
Vue.use(Vuex);
export const getDefaultState = () => ({
    persistent: {
        activeLanguage: undefined,
        dateInterval: 4,
        permissions: undefined,
        selectedCustomers: [],
        unitDetails: {},
        unitOptions: {},
        unitTypes: undefined,
        user: undefined,
        units: [],
        weather: {},
        widgetsHidden: { Data: ['UnitWeather'] },
        filterSettings: {},
        selectedUnitGroups: [],
    },
    transient: {
        mqttClient: undefined,
        textSearch: {},
        units: [],
    },
});
const index = {
    actions,
    getters,
    mutations,
    plugins: [
        createPersistedState({
            paths: [
                'persistent',
            ],
        })
    ],
    state: getDefaultState(),
    modules: {
        dashboard,
        container,
        setting,
        eventManagement,
    }
};
export default new Vuex.Store(index);
