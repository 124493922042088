import mqtt from 'mqtt';
import { debug } from '@/../../../config.js';
var Topic;
(function (Topic) {
    Topic[Topic["NewData"] = 0] = "NewData";
    Topic[Topic["Alarm"] = 1] = "Alarm";
    Topic[Topic["Unit"] = 2] = "Unit";
})(Topic || (Topic = {}));
export const nullConfiguration = {
    onUnit: () => undefined,
    onAlarm: () => undefined,
    onNewData: () => undefined,
};
const MQTT_BROKER_URL = 'wss://widdev.wideco.se';
const newDataTopicRegEx = /^widetect\/\d+\/\d+\/newdata$/;
const alarmTopicRegEx = /^widetect\/\d+\/alarm$/;
const unitTopicRegEx = /^widetect\/\d+\/unit$/;
const QOS_LEVEL = 1;
const defaultOptions = {
    port: 8884,
    protocolVersion: 3,
    protocolId: 'MQIsdp',
    keepalive: 60,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
};
export class MqttClient {
    config;
    client;
    subscriptions = [];
    subscriptionsQueue = [];
    constructor(client, config = nullConfiguration) {
        this.config = config;
        this.client = client;
        this.registerHandlers(this.client);
    }
    static connect(credentials, config = nullConfiguration) {
        const clientId = MqttClient.generateClientId(credentials.username);
        const brokerCredentials = {
            password: credentials.password,
            username: credentials.username.toLowerCase(), // Broker expects the username to be lowercase only
        };
        const clientOptions = {
            ...defaultOptions,
            ...brokerCredentials,
            clientId,
        };
        return new MqttClient(mqtt.connect(MQTT_BROKER_URL, clientOptions), config);
    }
    disconnect() {
        this.client.end();
    }
    selectCustomers(customers) {
        this.subscriptionsQueue = customers.map((customer) => customer.CustomerID);
        if (this.client.connected) {
            this.updateSubscriptions();
        }
    }
    static errorHandler(error) {
        if (error) {
            console.error(`MQTT Error: ${error}`);
        }
    }
    static generateClientId(username) {
        const normalizedUsername = username.toUpperCase().substr(0, 10);
        const randomString = Math.random().toString(16).substr(2, 15 - normalizedUsername.length);
        return `wision_${normalizedUsername}${randomString}`;
    }
    registerHandlers(client) {
        client.on('error', () => {
            console.error('Mqtt client connection error');
        });
        client.on('offline', () => {
            if (debug)
                console.info('Mqtt client offline');
        });
        client.on('connect', () => {
            if (debug)
                console.info('Connected to MQTT broker');
            this.updateSubscriptions();
        });
        client.on('message', this.handleMessage.bind(this));
    }
    updateSubscriptions() {
        // Subscribe to diff between subscriptions queue and current subscriptions
        this.subscriptionsQueue.filter((id) => !this.subscriptions.includes(id))
            .forEach((id) => this.subscribe.bind(this)(id));
        // Unsubscribe to diff between current subscriptions and subscriptions queue
        this.subscriptions.filter((id) => !this.subscriptionsQueue.includes(id))
            .forEach((id) => this.unsubscribe.bind(this)(id));
        this.subscriptions = this.subscriptionsQueue;
        this.subscriptionsQueue = [];
    }
    subscribe(customerId) {
        this.client.subscribe(`widetect/${customerId}/#`, { qos: QOS_LEVEL }, MqttClient.errorHandler);
    }
    unsubscribe(customerId) {
        this.client.unsubscribe(`widetect/${customerId}/#`, MqttClient.errorHandler);
    }
    handleMessage(topic, buffer) {
        if (debug)
            console.info(`MQTT message topic: ${topic}`);
        switch (MqttClient.getTopic(topic)) {
            case Topic.NewData:
                MqttClient.dispatchHandler(buffer, this.config.onNewData);
                break;
            case Topic.Alarm:
                MqttClient.dispatchHandler(buffer, this.config.onAlarm);
                break;
            case Topic.Unit:
                MqttClient.dispatchHandler(buffer, this.config.onUnit);
                break;
            default:
                if (debug)
                    console.info('MQTT: Unknown topic: ' + topic);
        }
    }
    static dispatchHandler(buffer, handler) {
        let messages;
        try {
            messages = JSON.parse(buffer.toString());
        }
        catch (error) {
            console.error(`MQTT: JSON parse error of message payload: ${error}`);
            return;
        }
        messages.forEach(handler);
    }
    static getTopic(topic) {
        if (topic.match(newDataTopicRegEx)) {
            return Topic.NewData;
        }
        else if (topic.match(alarmTopicRegEx)) {
            return Topic.Alarm;
        }
        else if (topic.match(unitTopicRegEx)) {
            return Topic.Unit;
        }
    }
}
