var ThemeMode;
(function (ThemeMode) {
    ThemeMode["Dark"] = "dark";
    ThemeMode["Bright"] = "bright";
})(ThemeMode || (ThemeMode = {}));
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["SET_DARK_MODE"] = "SET_DARK_MODE";
    MutationTypes["SET_BRIGHT_MODE"] = "SET_BRIGHT_MODE";
})(MutationTypes || (MutationTypes = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["TOGGLE_THEME_MODE"] = "TOGGLE_THEME_MODE";
})(ActionTypes || (ActionTypes = {}));
const state = {
    themeMode: ThemeMode.Dark,
};
const mutations = {
    /** Set dark mode **/
    [MutationTypes.SET_DARK_MODE](state) {
        state.themeMode = ThemeMode.Dark;
    },
    /** Set bright mode **/
    [MutationTypes.SET_BRIGHT_MODE](state) {
        state.themeMode = ThemeMode.Bright;
    },
};
const actions = {
    /** Toggle theme mode **/
    async [ActionTypes.TOGGLE_THEME_MODE](context) {
        if (context.state.themeMode === ThemeMode.Bright) {
            context.commit(MutationTypes.SET_DARK_MODE);
        }
        else {
            context.commit(MutationTypes.SET_BRIGHT_MODE);
        }
        return context.state.themeMode;
    },
};
const getters = {
    /** Get theme mode **/
    themeMode(state) {
        return state.themeMode;
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
