/**
 * @overview Vuex module that create an interface to state of containers
 */
import Vue from 'vue';
import { importGroup } from '@/lib/import-group';
import Keyv from '@keyvhq/core';
import apiAdapter from '@/lib/keyv-userapi';
import { debug } from '@/../../../config.js';
import { createContainer, insertReference, deleteReference, deleteReferences } from '@/core/models/container';
import { getErrorMessage } from '@/lib/helpers';
export var TypeIdent;
(function (TypeIdent) {
    TypeIdent["GROUPS"] = "groups";
    TypeIdent["LOCATIONS"] = "locations";
})(TypeIdent || (TypeIdent = {}));
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["SET_RECORD"] = "SET";
    MutationTypes["MERGE"] = "MERGE";
    MutationTypes["INSERT"] = "INSERT";
    MutationTypes["DELETE"] = "DELETE";
    MutationTypes["SET_LOADED"] = "SET_LOADED";
    MutationTypes["CLEAR_STATE"] = "CLEAR_STATE";
})(MutationTypes || (MutationTypes = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["FETCH"] = "FETCH";
    ActionTypes["SAVE"] = "SAVE";
    ActionTypes["MIGRATE"] = "MIGRATE";
    ActionTypes["INSERT_REFERENCE"] = "INSERT_REFERENCE";
    ActionTypes["DELETE_REFERENCE"] = "DELETE_REFERENCE";
    ActionTypes["DELETE_REFERENCES"] = "DELETE_REFERENCES";
    ActionTypes["CREATE"] = "CREATE";
    ActionTypes["DELETE"] = "DELETE";
    ActionTypes["DEBUG"] = "DEBUG";
    ActionTypes["RESET"] = "RESET";
})(ActionTypes || (ActionTypes = {}));
const KV_NAMESPACE = '_group';
const GROUPS_IDENT = 'groups';
const LOCATIONS_IDENT = 'locations';
export const defaultContainerState = {
    containers: {
        groups: {},
        locations: {},
    },
    hasLoaded: false,
};
const state = {
    containers: {
        groups: {},
        locations: {},
    },
    hasLoaded: false,
};
const kvAdapter = new apiAdapter({ namespace: KV_NAMESPACE });
const kv = new Keyv({ store: kvAdapter });
/** Validate containers record **/
const isContainerStorage = (data) => {
    const container = Object.values(data)?.[0];
    return !!container && !!container.name &&
        !!container.id &&
        !!container.content && typeof container.content == 'object';
};
const mutations = {
    /** Set that the containers has been loaded **/
    [MutationTypes.SET_LOADED](state) {
        state.hasLoaded = true;
    },
    /** Insert a full container record in to state record **/
    [MutationTypes.SET_RECORD](state, { typeId, record, }) {
        Vue.set(state.containers, typeId, record);
    },
    /** Merge a containers record object into a particular state record **/
    [MutationTypes.MERGE](state, { typeId, containers }) {
        Object.entries(containers).forEach(([id, container]) => {
            Vue.set(state.containers[typeId], id, container);
        });
    },
    /** Insert single container into the a particular state record **/
    [MutationTypes.INSERT](state, { typeId, container }) {
        Vue.set(state.containers[typeId], container.id, container);
    },
    /** Delete a container from the state record **/
    [MutationTypes.DELETE](state, { typeId, id }) {
        Vue.delete(state.containers[typeId], id);
    },
    /** Clear container state **/
    [MutationTypes.CLEAR_STATE](state) {
        Object.assign(state, defaultContainerState);
    },
};
const actions = {
    /** Fetch saved groups and locations from permanent storage **/
    async [ActionTypes.FETCH](context) {
        /** Clearing state before fetching new data **/
        context.state.containers[TypeIdent.GROUPS] = {};
        context.state.containers[TypeIdent.LOCATIONS] = {};
        const groups = await kv.get(GROUPS_IDENT);
        if (groups && isContainerStorage(groups)) {
            if (debug)
                console.info(`Loaded ${Object.keys(groups).length} groups`);
            context.commit(MutationTypes.MERGE, { typeId: TypeIdent.GROUPS, containers: groups });
        }
        else if (!groups) {
            context.state.containers[TypeIdent.GROUPS] = {};
        }
        else if (debug) {
            console.info('No valid user groups found');
        }
        const locations = await kv.get(LOCATIONS_IDENT);
        if (locations && isContainerStorage(locations)) {
            if (debug)
                console.info(`Loaded ${Object.keys(locations).length} locations`);
            context.commit(MutationTypes.MERGE, { typeId: TypeIdent.LOCATIONS, containers: locations });
        }
        else if (!locations) {
            context.state.containers[TypeIdent.LOCATIONS] = {};
        }
        else if (debug) {
            console.info('No valid user groups found');
        }
        context.commit(MutationTypes.SET_LOADED);
    },
    /** Clear container state **/
    async [ActionTypes.RESET](context) {
        context.commit(MutationTypes.CLEAR_STATE);
    },
    /** Save a containers record to permanent storage **/
    async [ActionTypes.SAVE](context, typeId) {
        return kv.set(typeId, context.state.containers[typeId]);
    },
    /** Migrate a customer into a group and multiple locations **/
    async [ActionTypes.MIGRATE](context, { customerId, name }) {
        try {
            const [groups, locations] = await importGroup(customerId, name);
            if (groups && isContainerStorage(groups)) {
                context.commit(MutationTypes.MERGE, { typeId: TypeIdent.GROUPS, containers: groups });
                await context.dispatch(ActionTypes.SAVE, TypeIdent.GROUPS);
            }
            else if (debug) {
                console.info('No valid groups data found');
            }
            if (locations && isContainerStorage(locations)) {
                context.commit(MutationTypes.MERGE, { typeId: TypeIdent.LOCATIONS, containers: locations });
                await context.dispatch(ActionTypes.SAVE, TypeIdent.LOCATIONS);
            }
            else if (debug) {
                console.info('No valid locations data found');
            }
        }
        catch (err) {
            if (debug)
                console.info('Import error :', getErrorMessage(err));
        }
    },
    /** Create a single container and insert it in state record
     * @returns {string} ID of new container
     **/
    async [ActionTypes.CREATE](context, { typeId, name, content }) {
        const container = createContainer({ name, content });
        const id = container.id;
        context.commit(MutationTypes.INSERT, { typeId, container });
        await context.dispatch(ActionTypes.SAVE, typeId);
        return id;
    },
    /** Insert a reference into a container **/
    async [ActionTypes.INSERT_REFERENCE](context, { typeId, id, reference }) {
        const container = insertReference({
            container: context.state.containers[typeId][id],
            reference,
        });
        context.commit(MutationTypes.INSERT, {
            typeId,
            container,
        });
        return context.dispatch(ActionTypes.SAVE, typeId);
    },
    /** Dump state **/
    async [ActionTypes.DEBUG](context) {
        console.info(JSON.stringify(context.state.containers, null, 2));
    },
    /** Delete container **/
    async [ActionTypes.DELETE](context, { typeId, id }) {
        context.commit(MutationTypes.DELETE, { typeId, id });
        return context.dispatch(ActionTypes.SAVE, typeId);
    },
    /** Delete reference **/
    async [ActionTypes.DELETE_REFERENCE](context, { typeId, id, target }) {
        const container = deleteReference({
            container: context.state.containers[typeId][id],
            target
        });
        context.commit(MutationTypes.INSERT, {
            typeId,
            container,
        });
        return context.dispatch(ActionTypes.SAVE, typeId);
    },
    /** Delete all references in container record **/
    async [ActionTypes.DELETE_REFERENCES](context, { typeId, target }) {
        const record = deleteReferences({
            record: context.state.containers[typeId],
            target,
        });
        context.commit(MutationTypes.SET_RECORD, {
            typeId,
            record,
        });
        return context.dispatch(ActionTypes.SAVE, typeId);
    }
};
const getters = {
    /** Get whether containers has been loaded **/
    hasLoaded(state) {
        return state.hasLoaded;
    },
    /** Get all locations under group **/
    locationsInGroup(state) {
        return (id) => {
            const group = state.containers[TypeIdent.GROUPS]?.[id];
            if (!group) {
                return [];
            }
            return group.content.map((locationReference) => locationReference.target);
        };
    },
    /** Get all unit IDs under group **/
    unitsInGroup(state, getters) {
        return (id) => {
            const group = state.containers[TypeIdent.GROUPS]?.[id];
            if (!group) {
                return [];
            }
            return group.content.map((locationReference) => {
                return getters['unitsInLocation'](locationReference.target);
            }).flat();
        };
    },
    /** Get all unit IDs in location **/
    unitsInLocation(state) {
        return (id) => {
            const location = state.containers[TypeIdent.LOCATIONS]?.[id];
            if (!location) {
                return [];
            }
            return location.content.map((deviceReference) => +deviceReference.target);
        };
    },
    /** Get all groups **/
    groups(state) {
        return Object.values(state.containers[TypeIdent.GROUPS]);
    },
    /** Get all locations **/
    locations(state) {
        return Object.values(state.containers[TypeIdent.LOCATIONS]);
    },
    /** Get all containers of a particular container type **/
    containers(state) {
        return (typeId) => {
            return Object.values(state.containers[typeId]);
        };
    },
    /** Get specific container of particular container type **/
    getById(state) {
        return (typeId, id) => {
            return state.containers[typeId]?.[id];
        };
    },
    /** Get all group names associated with a unit **/
    unitGroupNames(state, getters) {
        return (unitId) => {
            return getters.groups
                .map((group) => ([group.name, !!getters.unitsInGroup(group.id).includes(unitId)]))
                .filter(([, exist]) => exist)
                .map(([name,]) => name);
        };
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
