import EventEmitter from 'events';
import { deleteUserData, getUserData, setUserData } from '@wisionmonorepo/api-client-v1/src/requests';
import { debug } from '@/../../../config';
import { getErrorMessage } from '@/lib/helpers';
const DEFAULT_NAMESPACE = 'keyv';
class KeyvUserApi extends EventEmitter {
    namespace;
    constructor(options = { namespace: DEFAULT_NAMESPACE }) {
        super();
        this.namespace = options.namespace;
    }
    _getNamespace() {
        return this.namespace;
    }
    async *iterator() {
        try {
            const response = await getUserData({ type: this._getNamespace() });
            const iterator = response.map(({ ID, Data }) => [ID, Data]);
            yield* iterator;
        }
        catch (err) {
            if (debug)
                console.info('KV iterator error:', getErrorMessage(err));
            return;
        }
    }
    async get(key) {
        if (!key) {
            return undefined;
        }
        try {
            const response = await getUserData({ type: this._getNamespace(), id: key });
            if (!response.length) {
                return undefined;
            }
            else if (response.length > 1) {
                throw new Error('More than one keys received');
            }
            return response[0].Data;
        }
        catch (err) {
            if (debug)
                console.info('KV get error:', getErrorMessage(err));
            return undefined;
        }
    }
    async has(key) {
        return !!await this.get(key);
    }
    async set(key, value) {
        if (!key || !value) {
            return false;
        }
        try {
            await setUserData({
                type: this._getNamespace(),
                id: key,
                data: value,
                serialize: false, // Keyv library will do the serialization
            });
            return true;
        }
        catch (err) {
            if (debug)
                console.info('KV set error:', getErrorMessage(err));
            return false;
        }
    }
    async delete(key) {
        if (!key) {
            return false;
        }
        try {
            await deleteUserData({ type: this._getNamespace(), id: key });
            return true;
        }
        catch (err) {
            if (debug)
                console.info('KV delete error: ', getErrorMessage(err));
            return false;
        }
    }
    async clear() {
        try {
            const response = await getUserData({ type: this._getNamespace() });
            if (!response.length) {
                return;
            }
            response.forEach((userData) => this.delete(userData.ID));
            return;
        }
        catch (err) {
            if (debug)
                console.info('KV clear error: ', getErrorMessage(err));
            return;
        }
    }
}
export default KeyvUserApi;
