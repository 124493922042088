/**
 * @overview Vuex module for event management. Handles events, notifications and tasks.
 */
import { EntityKind } from '@/core/models/dbEntity';
import { createAlarmEvent, createAlarmId, EventType, modelVersion as eventModelVersion, migrateEventRecord, validateEventRecord, } from '@/core/models/event';
import { createAlarmNotification, markNotificationAsSeen, NotificationType, validateNotificationRecord, } from '@/core/models/notification';
import { addTaskEvents, createAlarmSignature, createAlarmTask, validateTaskRecord, } from '@/core/models/task';
import Vue from 'vue';
import apiAdapter from '@/lib/keyv-userapi';
import Keyv from '@keyvhq/core';
import { debug } from '@/../../../config.js';
import { getAlarms } from '@wisionmonorepo/api-client-v1/src/requests';
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["SET_RECORD"] = "SET_RECORD";
    MutationTypes["DELETE_RECORD"] = "DELETE_RECORD";
    MutationTypes["SET_DB_RECORD"] = "SET_DB_RECORD";
    MutationTypes["SET_RECORDS_LOADED"] = "SET_RECORDS_LOADED";
    MutationTypes["SET_ALARM_INDEX"] = "SET_ALARM_INDEX";
    MutationTypes["SET_ALARM_INDEXES"] = "SET_ALARM_INDEXES";
    MutationTypes["SET_ALARM_TIMER"] = "SET_ALARM_TIMER";
})(MutationTypes || (MutationTypes = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["FETCH_DB_RECORD"] = "FETCH_DB_RECORD";
    ActionTypes["SAVE_DB_RECORD"] = "SAVE_DB_RECORD";
    ActionTypes["SAVE_ALL"] = "SAVE_ALL";
    ActionTypes["FETCH_ALL"] = "FETCH_ALL";
    ActionTypes["CALCULATE_ALARM_INDEXES"] = "CALCULATE_ALARM_INDEXES";
    ActionTypes["IMPORT_ALARMS"] = "IMPORT_ALARMS";
    ActionTypes["MARK_NOTIFICATION_SEEN"] = "MARK_NOTIFICATIONS_SEEN";
    ActionTypes["ENABLE_ALARM_LISTENER"] = "ENABLE_ALARM_LISTENER";
    ActionTypes["DISABLE_ALARM_LISTENER"] = "DISABLE_ALARM_LISTENER";
    ActionTypes["DELETE_TASK"] = "DELETE_TASK";
    ActionTypes["UPDATE_TASK"] = "UPDATE_TASK";
})(ActionTypes || (ActionTypes = {}));
const state = {
    records: {
        [EntityKind.Event]: {},
        [EntityKind.Notification]: {},
        [EntityKind.Task]: {},
    },
    recordsLoaded: false,
    alarmIdIndex: {},
    alarmListenerTimer: undefined,
};
const KV_NAMESPACE = '_event_management';
const CHECK_ALARMS_INTERVAL = 60000;
const KV_KEYS = {
    [EntityKind.Event]: 'events',
    [EntityKind.Notification]: 'notifications',
    [EntityKind.Task]: 'tasks',
};
const kvAdapter = new apiAdapter({ namespace: KV_NAMESPACE });
const kv = new Keyv({ store: kvAdapter });
const mutations = {
    /**
     * Set single record
     */
    [MutationTypes.SET_RECORD]: (state, { model, data }) => {
        Vue.set(state.records[model], data.id, { ...data });
    },
    /**
     * Delete single record
     */
    [MutationTypes.DELETE_RECORD]: (state, { model, id }) => {
        Vue.delete(state.records[model], id);
    },
    /**
     * Set records loaded
     */
    [MutationTypes.SET_RECORDS_LOADED]: (state) => {
        Vue.set(state, 'recordsLoaded', true);
    },
    /**
     * Set complete DB record
     */
    [MutationTypes.SET_DB_RECORD]: (state, { model, data }) => {
        Vue.set(state.records, model, { ...data });
    },
    /**
     * Set alarm id indexes
     */
    [MutationTypes.SET_ALARM_INDEXES](state, idIndex) {
        state.alarmIdIndex = { ...idIndex };
    },
    /**
     * Set individual alarm id index
     */
    [MutationTypes.SET_ALARM_INDEX](state, { alarmId, eventId }) {
        state.alarmIdIndex[alarmId] = eventId;
    },
    /**
     * Set alarm timer
     */
    [MutationTypes.SET_ALARM_TIMER](state, timer) {
        state.alarmListenerTimer = timer;
    },
};
const actions = {
    /**
     * Save all
     */
    async [ActionTypes.SAVE_ALL](context) {
        return Promise.all([
            context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Notification }),
            context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Event }),
            context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task }),
        ]);
    },
    /**
     * Fetch all
     */
    async [ActionTypes.FETCH_ALL](context) {
        try {
            await Promise.all([
                context.dispatch(ActionTypes.FETCH_DB_RECORD, { model: EntityKind.Notification, validate: validateNotificationRecord }),
                context.dispatch(ActionTypes.FETCH_DB_RECORD, {
                    model: EntityKind.Event,
                    validate: validateEventRecord,
                    migrate: migrateEventRecord,
                    version: eventModelVersion
                }),
                context.dispatch(ActionTypes.FETCH_DB_RECORD, { model: EntityKind.Task, validate: validateTaskRecord }),
            ]);
            context.commit(MutationTypes.SET_RECORDS_LOADED);
            return context.dispatch(ActionTypes.CALCULATE_ALARM_INDEXES);
        }
        catch (error) {
            console.info(`Error fetching event management data: ${error}`);
        }
    },
    /**
     * Fetch db record from permanent storage
     */
    [ActionTypes.FETCH_DB_RECORD]: async (context, { model, validate, migrate, version }) => {
        const record = await kv.get(KV_KEYS[model]);
        if (validate(record)) {
            const currentRecord = migrate && version ? migrate(record, version) : record;
            context.commit(MutationTypes.SET_DB_RECORD, { model, data: currentRecord });
        }
        else if (debug)
            console.info('Invalid db record for', model);
    },
    /**
     * Save DB record to permanent storage
     */
    [ActionTypes.SAVE_DB_RECORD]: async (context, { model }) => {
        return kv.set(KV_KEYS[model], context.state.records[model]);
    },
    /**
     * Calculate and store alarm indexes
     */
    async [ActionTypes.CALCULATE_ALARM_INDEXES](context) {
        const values = Object.values(context.state.records[EntityKind.Event]);
        const indexes = Object.fromEntries(values
            .filter(event => event.type === EventType.Alarm)
            .map(event => {
            const alarmEvent = event;
            return [alarmEvent.alarmId, event.id];
        }));
        context.commit(MutationTypes.SET_ALARM_INDEXES, indexes);
    },
    /**
     * Mark notification as seen
     */
    async [ActionTypes.MARK_NOTIFICATION_SEEN](context, id) {
        const notification = context.state.records[EntityKind.Notification][id];
        if (!notification) {
            if (debug)
                console.info('Notification not found:', id);
            return;
        }
        context.commit(MutationTypes.SET_RECORD, { model: EntityKind.Notification, data: markNotificationAsSeen(notification) });
    },
    /**
     * Insert new alarms
     */
    async [ActionTypes.IMPORT_ALARMS]({ state, commit, dispatch, getters, rootGetters }, alarms) {
        if (!alarms || !state.recordsLoaded)
            return;
        const newAlarms = alarms.filter(alarm => !state.alarmIdIndex[createAlarmId(alarm)]);
        if (!newAlarms.length)
            return;
        await dispatch('cacheUnitDetails', newAlarms.map(alarm => alarm.UnitID), { root: true });
        for (const alarm of newAlarms) {
            const id = createAlarmId(alarm);
            if (debug)
                console.info('New alarm:', id);
            const unit = await dispatch('fetchUnitDetails', alarm.UnitID, { root: true });
            const groups = rootGetters['container/unitGroupNames'](unit.UnitID);
            const event = createAlarmEvent(alarm, unit, groups.length ? groups : undefined);
            const notification = createAlarmNotification(event);
            commit(MutationTypes.SET_RECORD, { model: EntityKind.Notification, data: notification });
            commit(MutationTypes.SET_RECORD, { model: EntityKind.Event, data: event });
            commit(MutationTypes.SET_ALARM_INDEX, { alarmId: event.alarmId, eventId: event.id });
            // Lookup existing alarm signature in active tasks
            const signature = createAlarmSignature(alarm);
            const existingTask = getters.tasksActive
                .find((task) => task.signature === signature);
            if (existingTask) { // Add event id to existing task if found
                const task = addTaskEvents(existingTask, [event.id]);
                commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
            }
            else { // Create new task
                const task = createAlarmTask({ events: [event] });
                commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
            }
        }
        dispatch(ActionTypes.SAVE_ALL);
    },
    /**
     * Enable periodic listening for alarms
     */
    async [ActionTypes.ENABLE_ALARM_LISTENER]({ commit, state, dispatch, rootGetters }) {
        const timer = setInterval(async () => {
            if (!state.recordsLoaded) {
                if (debug)
                    console.info('Data records not initialized');
                return;
            }
            const customerIds = rootGetters.selectedCustomerIds;
            if (!customerIds?.length)
                return;
            const alarms = await getAlarms({
                customerIds,
            });
            dispatch(ActionTypes.IMPORT_ALARMS, alarms);
        }, CHECK_ALARMS_INTERVAL);
        commit(MutationTypes.SET_ALARM_TIMER, timer);
    },
    /**
     * Disable periodic listening for alarms
     */
    async [ActionTypes.DISABLE_ALARM_LISTENER]({ state, commit }) {
        if (state.alarmListenerTimer) {
            clearInterval(state.alarmListenerTimer);
            commit(MutationTypes.SET_ALARM_TIMER, undefined);
        }
    },
    /**
     * Delete task
     */
    [ActionTypes.DELETE_TASK]: async (context, id) => {
        context.commit(MutationTypes.DELETE_RECORD, { model: EntityKind.Task, id });
        context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task });
    },
    /**
     * Update task
     */
    [ActionTypes.UPDATE_TASK]: async (context, task) => {
        if (task) {
            context.commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
            context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task });
        }
        else if (debug)
            console.info('Task update rejected because empty');
    },
};
const getters = {
    /**
     * Indicate if records has loaded
     */
    recordsLoaded(state) {
        return state.recordsLoaded;
    },
    /**
     * Get events by ids
     */
    eventsByIds(state) {
        return (eventIds) => eventIds.map((id) => state.records[EntityKind.Event][id]);
    },
    /**
     * Get event by id
     */
    eventById(state) {
        return (id) => {
            return state.records[EntityKind.Event][id];
        };
    },
    /**
     * Get the notifications record sorted by date
     */
    notifications(state) {
        const notifications = Object.values(state.records[EntityKind.Notification]);
        return notifications.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });
    },
    /**
     * Get a single notification
     */
    notificationById(state) {
        return (id) => {
            return state.records[EntityKind.Notification][id];
        };
    },
    /**
     * Get the count of all notifications
     */
    notificationCount(state) {
        return Object.values(state.records[EntityKind.Notification]).length;
    },
    /**
     * Get the count of alarms
     */
    notificationAlarmCount(state) {
        const notifications = Object.values(state.records[EntityKind.Notification]);
        return notifications
            .filter((notification) => notification.type === NotificationType.Alarm)
            .length;
    },
    /**
     * Get the count of unseen notifications
     */
    notificationUnseenCount(state) {
        const notifications = Object.values(state.records[EntityKind.Notification]);
        return notifications
            .filter((notification) => !notification.seen)
            .length;
    },
    /**
     * Get tasks sorted by date
     */
    tasks(state) {
        const tasks = Object.values(state.records[EntityKind.Task]);
        return tasks
            .sort((a, b) => (new Date(b.created).getTime() - new Date(a.created).getTime()));
    },
    /**
     * Get a single task
     */
    taskById(state) {
        return (id) => {
            return state.records[EntityKind.Task][id];
        };
    },
    /**
     * Get active tasks sorted by date
     */
    tasksActive(state) {
        const tasks = Object.values(state.records[EntityKind.Task]);
        return tasks
            .sort((a, b) => (new Date(b.created).getTime() - new Date(a.created).getTime()));
    },
    /**
     * Get a task notes
     */
    taskNotes(state) {
        return (id) => {
            return state.records[EntityKind.Task][id].activities
                .filter(activity => activity.type === 'note');
        };
    },
    /**
     * Get a tasks latest event
     */
    taskLatestEvent(state) {
        return (id) => {
            const task = state.records[EntityKind.Task][id];
            const lastEventId = task.eventIds[task.eventIds.length - 1];
            return state.records[EntityKind.Event][lastEventId];
        };
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
