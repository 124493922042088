/**
 * @overview Helper functions
 */
/**
 * Extract error message from unknown error type
 */
export const getErrorMessage = (error) => {
    if (error instanceof Error)
        return error.message;
    return String(error);
};
