import { UnitStatusNames, AlarmTypes } from './enums';
const NOT_INSTALLED_INDICATOR = 'Ej Installerad';
export const getAlarmStatus = (alarm) => {
    if (alarm.AlarmType === AlarmTypes.COMMUNICATION_ERROR) {
        return UnitStatusNames.Problem;
    }
    return UnitStatusNames.Alarm;
};
export const getUnitStatus = (unit) => {
    const isAlarmEvent = (event) => typeof event.AlarmType === 'number' && event.AlarmType >= 0;
    const result = {
        alarm: false,
        notInstalled: false,
        ok: false,
        problem: false,
    };
    result.notInstalled = unit
        .UnitName
        .indexOf(NOT_INSTALLED_INDICATOR) > -1;
    result.problem = !result.notInstalled && unit
        .AlarmEvents
        .filter((alarmEvent) => alarmEvent.AlarmType === AlarmTypes.COMMUNICATION_ERROR)
        .length > 0;
    result.alarm = !result.notInstalled && !result.problem && unit
        .AlarmEvents
        .filter(isAlarmEvent)
        .length > 0;
    result.ok = !result.problem && !result.alarm && !result.notInstalled;
    return result;
};
export const getUnitStatusName = (unit) => {
    let result = UnitStatusNames.Ok;
    const status = getUnitStatus(unit);
    if (status.alarm) {
        result = UnitStatusNames.Alarm;
    }
    else if (status.problem) {
        result = UnitStatusNames.Problem;
    }
    else if (status.notInstalled) {
        result = UnitStatusNames.NotInstalled;
    }
    return result;
};
export const getSelectedStatus = (status) => ({
    alarm: status === UnitStatusNames.Alarm,
    notInstalled: status === UnitStatusNames.NotInstalled,
    ok: status === UnitStatusNames.Ok,
    problem: status === UnitStatusNames.Problem,
});
