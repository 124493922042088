module.exports = {
  debug: false, //Enable to display console logs for debugging
  alarmTimeFormat: 'L LT',
  cookieAgeDays: 28,
  cookieKey: '{k8o<;$&1"+PA7q/x/xzddO+{QI_1',
  dateFormat: 'YYYY-MM-DD',
  fileCert: 'cert/xtool-local.crt',
  fileKey: 'cert/xtool-local.key',
  // ipgeolocation: 'https://api.ipgeolocation.io/ipgeo?apiKey=e40577847f9b4e28ac81c1321d77bbd3',
  ipApi: 'http://ip-api.com/json/',
  languages: 'sv,en,de,da',
  logFormat: ':date[clf] :remote-addr :username :method :url :status :response-time',
  googleMapsApiKey: 'AIzaSyBv2WZyARcGV0ct-14PIngxB9VBobEVbaQ',
  listenHttp: true,
  listenHttps: false,
  portHttp: 3000,
  portHttps: 443,
  timeFormat: 'HH:mm',
  unitsPerPage: 20,
  verifySSL: false,
  weatherApiPrefix: 'https://api.darksky.net/forecast/d325c9e8b1745578341ab50a98c10a83/',
  weatherApiSuffix: '?exclude=currently,minutely,hourly,alerts,flags&units=si',
  widecoApi: 'https://widdev.wision.io:1767/datasnap/rest/TServerMethods1',
  logoPath: '/assets/logo/wideco-logo.svg',
  contactUrl: 'https://wideco.se/kontakt/wideco-sweden-ab/',
  logoPathPNG: '/assets/logo/wideco-logo.png',
  website: 'www.wideco.se',
  copyrightText: 'Copyright Wideco Sweden AB 2021 @',
};
