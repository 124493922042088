export const endpoints = {
    AuthLogin: 'auth/login',
    AuthLogout: 'auth/logout',
    DeleteUserData: 'DeleteUserData',
    EditUnitInfo: 'EditUnitInfo',
    GetAlarms: 'GetAlarms',
    GetAllMarks: 'GetAllMarks',
    EditMark: 'EditMark',
    DeleteMark: 'DeleteMark',
    GetGISData: 'GetGISData',
    GetKPIAlarms: 'GetKPIAlarms',
    GetKPIBattery: 'GetKPIBattery',
    GetKPISwitch: 'GetKPISwitch',
    GetRadar: 'GetRadar',
    GetRadarDates: 'GetRadarDates',
    GetReportParkingUsage: 'GetReportParkingUsage',
    GetUnit: 'GetUnit',
    GetUnitGroups: 'GetUnitGroups',
    GetUnitType: 'GetUnitType',
    GetUnitsByPos: 'GetUnitsByPos',
    GetUnitsDataCSV: 'GetUnitsDataCSV',
    GetUnitsData: 'GetUnitsData',
    GetUnitsInfo: 'GetUnitsInfo',
    GetUnitsShowValue: 'GetUnitsShowValue',
    GetUserData: 'GetUserData',
    SetRelay: 'SetRelay',
    GetWeather: 'weather/getWeather',
    SetUserData: 'SetUserData',
    LoginAccess: 'LoginAccess',
    UnitAction: 'UnitAction',
    GetChannel: 'GetChannel',
    EditChannel: 'EditChannel',
};
