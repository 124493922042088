import { getUnitGroups, getUnitsByPos } from '@wisionmonorepo/api-client-v1/src/requests';
import { createContainer, createReference, ReferenceType, } from '@/core/models/container';
export const importGroup = async (customerId, groupName) => {
    const groups = {};
    if (!(customerId && groupName)) {
        throw new Error('Missing parameters');
    }
    const groupsResponse = await getUnitGroups({ customerId });
    const locations = await createLocations(customerId, groupsResponse);
    const locationReferences = Object.entries(locations)
        .map(([id, location]) => createReference({
        name: location.name,
        type: ReferenceType.Location,
        target: id,
    }));
    const group = createContainer({ name: groupName, content: locationReferences });
    groups[group.id] = group;
    return [groups, locations];
};
const createLocations = async (customerId, groupsResponse) => {
    const locations = {};
    for (const group of groupsResponse) {
        const units = await getUnitsInGroup(customerId, group.GroupId);
        if (!units.length)
            continue;
        const unitReferences = units.map((unit) => createReference({
            name: unit.UnitName,
            type: ReferenceType.Unit,
            target: unit.UnitID
        }));
        const location = createContainer({ name: group.Description, content: unitReferences });
        locations[location.id] = location;
    }
    return locations;
};
const getUnitsInGroup = async (customerId, groupId) => {
    return await getUnitsByPos({
        customerIds: [customerId],
        unitGroupIds: [groupId],
    });
};
