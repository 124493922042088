export default {
    dateInterval(state) {
        return state.persistent.dateInterval;
    },
    filterSettings(state) {
        const filterSettings = {};
        for (const key of Object.keys(state.persistent.filterSettings)) {
            filterSettings[key] = state.persistent.filterSettings[key];
            filterSettings[key].textSearch = state.transient.textSearch[key] || '';
        }
        return filterSettings;
    },
    hiddenWidgets(state) {
        return (view) => state.persistent.widgetsHidden[view] || [];
    },
    selectedCustomerIds(state) {
        return state
            .persistent
            .selectedCustomers
            .map((customer) => customer.CustomerID);
    },
    unitTypes(state) {
        return state.persistent.unitTypes;
    },
    selectedGisCustomers(state) {
        const selectedCustomerIds = state.persistent.selectedCustomers.map((c) => c.CustomerID);
        return state.persistent.permissions?.gisCustomers.filter((c) => selectedCustomerIds.includes(c)) || [];
    },
    showParking(state) {
        const selectedCustomerIds = state.persistent.selectedCustomers.map((c) => c.CustomerID);
        return !!state.persistent.permissions?.parkingCustomers.some((c) => selectedCustomerIds.includes(c));
    },
    textSearch(state) {
        return state.transient.textSearch;
    },
    user(state) {
        return state.persistent.user;
    },
    currentUnits(state) {
        return state.persistent.units;
    },
    unitDetails(state) {
        return state.persistent.unitDetails.unitID;
    },
    selectedUnitGroups(state) {
        return state.persistent.selectedUnitGroups;
    },
};
