import { nanoid } from 'nanoid';
import { i18n } from '@/core/i18n';
export const DEFAULT_LINK_GROUP_ID = 'default';
const DEFAULT_LINK_GROUP_NAME = 'default';
const DEFAULT_LINK_GROUP_COLOR = 'group-one';
export const DEFAULT_LINK_GROUPS = {
    [DEFAULT_LINK_GROUP_ID]: {
        id: DEFAULT_LINK_GROUP_ID,
        name: DEFAULT_LINK_GROUP_NAME,
        color: DEFAULT_LINK_GROUP_COLOR,
    }
};
export const appendLinkGroup = (linkGroups, name, color) => {
    const id = nanoid(10);
    const newGroup = {
        [id]: {
            id,
            name,
            color,
        }
    };
    return {
        ...linkGroups,
        ...newGroup,
    };
};
export const replaceNameTranslation = (linkGroups) => {
    linkGroups['default'].name = i18n.t(linkGroups['default'].name).toString();
    return linkGroups;
};
