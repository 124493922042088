/**
 * @overview Vuex module that exposes an interface to a state of dashboards
 */
import Vue from 'vue';
import { i18n } from '@/core/i18n';
import { staticDashboards, emptyDashboard } from '@/core/models/dashboard';
import { nanoid } from 'nanoid';
import clone from 'just-clone';
import { debug } from '@/../../../config.js';
import Keyv from '@keyvhq/core';
import apiAdapter from '@/lib/keyv-userapi';
import { DEFAULT_LINK_GROUP_ID, DEFAULT_LINK_GROUPS } from '@/core/models/linkGroup';
import { getErrorMessage } from '@/lib/helpers';
const state = {
    dashboards: {},
    hasLoaded: false,
    saveDelayTimer: {},
};
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["SET"] = "SET";
    MutationTypes["DELETE"] = "DELETE";
    MutationTypes["SET_LOADED"] = "SET_LOADED";
})(MutationTypes || (MutationTypes = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SAVE"] = "SAVE";
    ActionTypes["DELETE"] = "DELETE";
    ActionTypes["ADD"] = "ADD";
    ActionTypes["UPDATE"] = "UPDATE";
    ActionTypes["FETCH"] = "FETCH";
    ActionTypes["RESTORE"] = "RESTORE";
})(ActionTypes || (ActionTypes = {}));
const SAVE_DELAY = 2000;
export const KV_NAMESPACE = 'DashboardLayouts';
const kvAdapter = new apiAdapter({ namespace: KV_NAMESPACE });
const kv = new Keyv({ store: kvAdapter });
/** Validate dashboard model **/
const isDashboard = (data) => {
    return data && data.name && typeof (data.name) == 'string' &&
        (data.layout && typeof (data.layout) == 'object' ||
            data.layouts && typeof (data.layouts) == 'object') &&
        data.widgets && typeof (data.widgets) == 'object';
};
/** Migrate dashboard models of previous versions to current **/
const migratePrevVersions = (dashboard) => {
    let change = false;
    // Make sure models exist
    if (!dashboard.models) {
        dashboard.models = {};
        change = true;
    }
    // Make sure linkGroups exists and is valid
    if (!dashboard.linkGroups && typeof dashboard.linkGroups !== 'object') {
        if (debug)
            console.info('migrating link groups in ', dashboard.name);
        dashboard.linkGroups = DEFAULT_LINK_GROUPS;
        // Assign the default group to any widget that has a group defined
        Object.values(dashboard.widgets).forEach((widget) => {
            if (widget?.group)
                widget.group = DEFAULT_LINK_GROUP_ID;
        });
        change = true;
    }
    return change;
};
/** Replace the i18n references in the dashboard model name with the actual translations   **/
const replaceTranslations = (dashboards) => {
    const copy = clone(dashboards);
    Object.keys(copy).forEach((id) => copy[id].name = i18n.t(copy[id].name).toString());
    return copy;
};
const mutations = {
    /** Store dashboard model **/
    [MutationTypes.SET](state, { id, dashboard }) {
        Vue.set(state.dashboards, id, dashboard);
    },
    /** Remove dashboard model **/
    [MutationTypes.DELETE](state, id) {
        Vue.delete(state.dashboards, id);
    },
    /** Set dashboards is loaded **/
    [MutationTypes.SET_LOADED](state) {
        state.hasLoaded = true;
    },
};
const actions = {
    /** Save dashboards to permanent storage **/
    async [ActionTypes.SAVE]({ state }, id) {
        const dashboard = state.dashboards[id];
        if (!dashboard) {
            if (debug)
                console.info('Save: dashboard does not exist: ' + id);
            return;
        }
        if (debug)
            console.info('Saving dashboard: ' + id);
        // Save layout after a fixed delay to prevent flooding the API with save requests
        if (state.saveDelayTimer[id]) {
            clearTimeout(state.saveDelayTimer[id]);
        }
        // Pass copy of cached dashboard to prevent cache from being reassigned during delay
        const currentDashboard = clone(state.dashboards[id]);
        state.saveDelayTimer[id] = global.setTimeout(async () => {
            await kv.set(id, currentDashboard);
            delete (state.saveDelayTimer[id]);
        }, SAVE_DELAY);
    },
    /** Delete dashboard model **/
    async [ActionTypes.DELETE](context, id) {
        if (debug)
            console.info('Deleting dashboard: ' + id);
        await kv.delete(id);
        context.commit(MutationTypes.DELETE, id);
    },
    /** Add dashboard model to state **/
    async [ActionTypes.ADD](context, name) {
        const dashboard = clone(emptyDashboard);
        dashboard.name = name;
        // Make sure id is unique, even though collision is unlikely
        let id = nanoid();
        while (state.dashboards[id]) {
            id = nanoid();
        }
        context.commit(MutationTypes.SET, { id, dashboard });
        await context.dispatch(ActionTypes.SAVE, id);
        return id;
    },
    /** Update dashboard in state **/
    async [ActionTypes.UPDATE](context, payload) {
        const current = context.state.dashboards[payload.id];
        context.commit(MutationTypes.SET, {
            id: payload.id,
            dashboard: { ...current, ...payload.parts }
        });
        await context.dispatch(ActionTypes.SAVE, payload.id);
    },
    /** Restore dashboard model **/
    async [ActionTypes.RESTORE](context, id) {
        const dashboard = clone(staticDashboards[id]);
        dashboard.name = i18n.t(staticDashboards[id].name).toString();
        // Need to pre-commit an empty dashboard in order to get vue-grid-layout to pick up the changes
        context.commit(MutationTypes.SET, { id, dashboard: emptyDashboard });
        Vue.nextTick(() => {
            context.dispatch(ActionTypes.UPDATE, { id, parts: dashboard });
        });
    },
    /** Fetch dashboards from permanent storage **/
    async [ActionTypes.FETCH](context) {
        // Seed state with the static dashboards that has translation keys replaced
        state.dashboards = replaceTranslations(staticDashboards);
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            for await (const [id, dashboard] of kv.iterator()) {
                if (isDashboard(dashboard)) {
                    const changed = migratePrevVersions(dashboard);
                    context.commit(MutationTypes.SET, { id, dashboard });
                    if (changed)
                        await context.dispatch(ActionTypes.SAVE, id);
                    if (debug)
                        console.info(`Loading dashboard: ${id}: ${dashboard.name}`);
                }
                else {
                    const raw = await kv.get(id, { raw: true });
                    if (isDashboard(raw)) {
                        // Migrate dashboards which has not yet been saved as a Keyv value
                        if (debug)
                            console.info('Migrating dashboard', id);
                        context.commit(MutationTypes.SET, { id, dashboard: raw });
                        await context.dispatch(ActionTypes.SAVE, id);
                    }
                    else {
                        if (debug)
                            console.info(`Removing invalid dashboard ${id}`);
                        await kv.delete(id);
                    }
                }
            }
        }
        catch (err) {
            console.error(`Error fetching user dashboards: ${getErrorMessage(err)}`);
            context.commit(MutationTypes.SET_LOADED);
            throw err;
        }
        context.commit(MutationTypes.SET_LOADED);
    },
};
const getters = {
    /** All dashboard models **/
    dashboards(state) {
        return state.dashboards;
    },
    /** Has dashboards been loaded? **/
    hasLoaded(state) {
        return state.hasLoaded;
    },
    /** Get dashboard model by ID **/
    getById(state) {
        return (id) => {
            return state.dashboards[id];
        };
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
