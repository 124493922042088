import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessages from '@/i18n/messages/en.json';
import moment from 'moment';
import { languages } from '@wisionmonorepo/core-device-support/src/enums';
Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    messages: { en: enMessages },
});
const loadedLanguages = ['en'];
const setI18nLanguage = (lang) => {
    i18n.locale = lang;
    document.querySelector('html')
        ?.setAttribute('lang', lang);
    return lang;
};
export const loadLanguageAsync = async (lang) => {
    moment.locale(lang);
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/messages/${lang}.json`)
        .then((messages) => {
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
};
export const setUserLanguage = async (languageId) => {
    return loadLanguageAsync(!languageId ? 'en' : (languages[languageId] || 'en'));
};
export const initializeLanguage = async (store) => {
    const state = store.state.persistent;
    if (state.activeLanguage) {
        await loadLanguageAsync(state.activeLanguage);
    }
    else if (state.user && state.user.Language) {
        const lang = languages[state.user.Language] || 'en';
        await loadLanguageAsync(lang);
    }
    else {
        await loadLanguageAsync('en');
    }
};
