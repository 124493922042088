import { getUnitStatus } from '@wisionmonorepo/core-device-support/src/status';
import { i18n } from './i18n';
import { PARKING_UNITS, UNIT_TYPE_NAME } from '@wisionmonorepo/core-device-support/src/unitTypes';
const defaultUnitStatus = {
    alarm: false,
    notInstalled: false,
    ok: false,
    problem: false,
};
export const defaultFilterSettings = {
    unitStatus: defaultUnitStatus,
    unitTypes: [],
    alarmTypes: [],
    textSearch: '',
};
export const alarmStatusFilter = {
    unitStatus: {
        alarm: true,
        notInstalled: false,
        ok: false,
        problem: false
    },
};
export const parkingUnitFilter = {
    unitTypes: [{
            UnitTypes: PARKING_UNITS.join(','),
        }],
};
export const getFilterSettings = (alarmTypes, unitStatus, unitTypes) => {
    return {
        alarmTypes: alarmTypes ? alarmTypes : defaultFilterSettings.alarmTypes,
        unitStatus: unitStatus ? unitStatus : defaultFilterSettings.unitStatus,
        unitTypes: unitTypes ? unitTypes : defaultFilterSettings.unitTypes,
    };
};
export const filterUnits = (units, overrides = {}, filterSettings = defaultFilterSettings) => {
    const { unitStatus, unitTypes, alarmTypes, searchTerm } = overrides;
    const filterUnitStatus = unitStatus || filterSettings.unitStatus;
    const filterUnitTypes = unitTypes || filterSettings.unitTypes;
    const filterAlarmTypes = alarmTypes || filterSettings.alarmTypes;
    const filterSearchTerm = searchTerm || filterSettings.textSearch;
    return new Filter(filterUnitTypes, filterUnitStatus, filterAlarmTypes, filterSearchTerm).getFilteredUnits(units);
};
export class Filter {
    allStatus;
    searchWord;
    selectedAlarmTypes;
    selectedStatus;
    selectedTypes;
    constructor(selectedTypes, selectedStatus, selectedAlarmTypes, searchWord) {
        this.selectedTypes = selectedTypes;
        this.selectedStatus = selectedStatus;
        this.selectedAlarmTypes = selectedAlarmTypes;
        this.searchWord = !searchWord ? '' : searchWord
            .trim()
            .toLowerCase();
        this.allStatus =
            (selectedStatus.alarm && selectedStatus.problem && selectedStatus.notInstalled && selectedStatus.ok) ||
                (!selectedStatus.alarm && !selectedStatus.problem && !selectedStatus.notInstalled && !selectedStatus.ok);
    }
    getFilteredAlarms(alarms) {
        return alarms.filter(this.isMatch.bind(this));
    }
    getFilteredUnits(units) {
        return units.filter(this.isMatch.bind(this));
    }
    isAlarmMatch(item) {
        const radix = 10;
        const selectedAlarmTypes = this.selectedAlarmTypes.map((alarmType) => parseInt(alarmType, radix));
        if (this.selectedAlarmTypes.length === 0) {
            return true;
        }
        if ('AlarmID' in item) {
            return selectedAlarmTypes.includes(item.AlarmType);
        }
        return selectedAlarmTypes.some((alarmType) => item.AlarmEvents.map((alarmEvent) => alarmEvent.AlarmType).includes(alarmType));
    }
    isCategoryMatch = (data, unitType) => {
        const unitTypes = unitType
            .UnitTypes
            .replace(/^\(|\)$/g, '')
            .split(',');
        if ('AlarmID' in data) {
            return unitTypes
                .some((category) => category === (data.Units[0].UnitType).toString());
        }
        return unitTypes
            .some((category) => category === data.UnitType.toString());
    };
    isMatch(data) {
        if ('AlarmID' in data) {
            return this.isSearchWordMatch(data) &&
                this.isTypeMatch(data) &&
                this.isAlarmMatch(data);
        }
        return this.isSearchWordMatch(data) &&
            this.isStatusMatch(data) &&
            this.isTypeMatch(data) &&
            this.isAlarmMatch(data);
    }
    isSearchWordMatch(data) {
        if (!this.searchWord) {
            return true;
        }
        if ('AlarmID' in data) {
            return this.isTextMatch(data.AlarmEventText) ||
                this.isTextMatch(i18n.t(`alarmtypes.${data.AlarmType}`).toString()) ||
                this.isTextMatch(UNIT_TYPE_NAME[data.Units[0].UnitType]);
        }
        return this.isTextMatch(data.UnitName) ||
            this.isTextMatch(data.UnitLocation) ||
            this.isTextMatch(data.UnitType.toString());
    }
    isStatusMatch(unit) {
        let result = true;
        if (!this.allStatus) {
            const unitStatus = getUnitStatus(unit);
            result =
                (this.selectedStatus.alarm && unitStatus.alarm) ||
                    (this.selectedStatus.ok && unitStatus.ok) ||
                    (this.selectedStatus.problem && unitStatus.problem) ||
                    (this.selectedStatus.notInstalled && unitStatus.notInstalled);
        }
        return result;
    }
    isTextMatch(text) {
        if (!text) {
            return false;
        }
        const cleanText = text
            .trim()
            .toLowerCase();
        return cleanText.indexOf(this.searchWord) > -1;
    }
    isTypeMatch(data) {
        if (this.selectedTypes.length === 0) {
            return true;
        }
        return this
            .selectedTypes
            .some((unitType) => this.isCategoryMatch(data, unitType));
    }
}
