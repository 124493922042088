import { getUnitsInfo, getUnitsShowValue, getUnitType, getWeather, login, loginAccess, logout } from '@wisionmonorepo/api-client-v1/src/requests';
import { ActionTypes as DashboardActions } from '@/core/store/modules/dashboard';
import { ActionTypes as ContainerActions } from '@/core/store/modules/container';
import { ActionTypes as EventActions } from '@/core/store/modules/event-management';
import { createPermissions } from '@/core/models/permissions';
import { MqttClient } from '@/core/mqttClient';
import { debug } from '@/../../../config.js';
import { getUnitsByPos } from '@wisionmonorepo/api-client-v1/src/requests';
export default {
    clearState(context) {
        context.commit('CLEAR_STATE');
    },
    async login(context, credentials) {
        const result = await login(credentials.username, credentials.password);
        context.commit('SET_USER', result);
        await Promise.all([
            context.dispatch('fetchPermissions'),
            // context.dispatch('mqttClientLogin', credentials),
            context.dispatch(`dashboard/${DashboardActions.FETCH}`),
            context.dispatch(`eventManagement/${EventActions.ENABLE_ALARM_LISTENER}`),
            context.dispatch('fetchUnitTypes'),
            context.dispatch('fetchCurrentUnits'),
        ]);
        return result;
    },
    async logout(context) {
        // context.dispatch('mqttClientClose');
        await Promise.all([
            context.dispatch(`container/${ContainerActions.RESET}`),
            context.dispatch(`eventManagement/${EventActions.DISABLE_ALARM_LISTENER}`),
            context.commit('CLEAR_STATE'),
        ]);
        logout();
    },
    async fetchChartOptions(context, unitId) {
        const customerIds = context.getters.selectedCustomerIds;
        if (!context.state.persistent.unitOptions[unitId]) {
            const optionsResponse = await getUnitsShowValue({
                customerIds,
                unitIds: [unitId],
            });
            context.commit('SET_UNIT_OPTIONS', optionsResponse);
        }
        return context.state.persistent.unitOptions[unitId];
    },
    async fetchPermissions(context) {
        if (!context.state.persistent.permissions) {
            const response = await loginAccess();
            const permissions = createPermissions(response);
            context.commit('SET_PERMISSIONS', permissions);
        }
    },
    async fetchUnitOptions(context, units) {
        const customerIds = context.getters.selectedCustomerIds;
        const unitIds = units.map((unit) => unit.UnitID);
        const optionsResponse = await getUnitsShowValue({ unitIds, customerIds });
        context.commit('SET_UNIT_OPTIONS', optionsResponse);
        return optionsResponse;
    },
    async cacheUnitDetails(context, unitIds) {
        const missingIds = unitIds.filter(unitId => !context.state.persistent.unitDetails[unitId]);
        if (!missingIds.length)
            return;
        if (debug)
            console.info('Caching unit details:', missingIds);
        const response = await getUnitsInfo({ unitIds: missingIds });
        response.forEach((unit) => {
            context.commit('SET_UNIT', unit);
        });
    },
    async fetchUnitDetails(context, unitId) {
        if (!context.state.persistent.unitDetails[unitId]) {
            const response = await getUnitsInfo({ unitIds: [unitId] });
            context.commit('SET_UNIT', response[0]);
        }
        return context.state.persistent.unitDetails[unitId] || {};
    },
    async fetchCurrentUnits(context, force = false) {
        if (context.state.persistent.units.length && !force) {
            return;
        }
        const response = await getUnitsByPos({
            customerIds: context.getters.selectedCustomerIds,
        });
        if (response) {
            context.commit('SET_UNITS', response);
        }
        if (debug)
            console.info('Unable to cache units');
    },
    async fetchUnitTypes(context) {
        if (!context.state.persistent.unitTypes) {
            const customerIds = context.getters.selectedCustomerIds;
            const response = await getUnitType({ customerIds });
            if (response.length > 0) {
                context.commit('SET_UNIT_TYPES', response);
            }
        }
        return context.state.persistent.unitTypes || [];
    },
    async fetchWeather(context, unit) {
        if (!context.state.persistent.weather[unit.UnitID]) {
            const response = await getWeather({
                latitude: unit.UnitGPSLatitude,
                longitude: unit.UnitGPSLongitude
            });
            response.id = unit.UnitID;
            context.commit('SET_WEATHER', response);
        }
        return context.state.persistent.weather[unit.UnitID] || {};
    },
    mqttClientLogin(context, credentials) {
        const client = MqttClient.connect(credentials, {
            onNewData: (message) => { if (debug)
                console.info(`NewData: ${JSON.stringify(message)}`); },
            onAlarm: (message) => { if (debug)
                console.info(`Alarm: ${JSON.stringify(message)}`); },
            onUnit: (message) => { if (debug)
                console.info(`Unit: ${JSON.stringify(message)}`); },
        });
        context.commit('SET_MQTT_CLIENT', client);
        context.dispatch('mqttClientUpdateCustomers');
    },
    mqttClientClose(context) {
        if (context.state.transient.mqttClient) {
            context.state.transient.mqttClient?.disconnect();
            context.commit('SET_MQTT_CLIENT', undefined);
        }
    },
    mqttClientUpdateCustomers(context) {
        context.state.transient.mqttClient?.selectCustomers(context.state.persistent.selectedCustomers);
    },
    setActiveLanguage(context, language) {
        context.commit('SET_ACTIVE_LANGUAGE', language);
    },
    setDateInterval(context, dateInterval) {
        context.commit('SET_DATE_INTERVAL', dateInterval);
    },
    async toggleCustomer(context, customer) {
        const filter = (item) => item.CustomerID !== customer.CustomerID;
        let customers = context.state.persistent.selectedCustomers;
        if (customers.filter(filter).length === customers.length) {
            customers.push(customer);
        }
        else {
            customers = customers.filter(filter);
        }
        await context.dispatch('setSelectedCustomers', customers);
    },
    async setSelectedCustomers(context, customers) {
        context.commit('SET_SELECTED_CUSTOMERS', customers);
        context.dispatch('fetchCurrentUnits', true);
        // context.dispatch('mqttClientUpdateCustomers');
    },
    setTextSearch(context, { id, textSearch }) {
        context.commit('SET_TEXT_SEARCH', { id, textSearch });
    },
    setFilterSettings(context, { id, filterSettings }) {
        context.commit('SET_FILTER_SETTINGS', { id, filterSettings });
    },
    removeFilterSettings(context, id) {
        context.commit('REMOVE_FILTER_SETTINGS', id);
    },
    setSelectedUnitGroups(context, unitGroups) {
        context.commit('SET_SELECTED_UNIT_GROUPS', unitGroups);
    },
};
