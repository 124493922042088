// tslint:disable
import { i18n, initializeLanguage } from '@/core/i18n';
import { router } from '@/core/router';
import store from '@/core/store';
import { Toast } from '@/core/toast';
import axios from 'axios';
import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
import App from './App.vue';
import '@/styles/index.css';
import '@/styles/global.css';
import { ActionTypes as ContainerActions } from '@/core/store/modules/container';
import { ActionTypes as DashboardActions } from '@/core/store/modules/dashboard';
import { ActionTypes as EventActions } from '@/core/store/modules/event-management';
Vue.config.productionTip = false;
Vue.use(ToggleButton);
const vue = new Vue({
    i18n,
    render: (h) => h(App),
    router,
    store,
});
vue.$mount('#app');
(async () => {
    // Make sure language is loaded before proceeding with loading data
    await initializeLanguage(store);
    if (store.state.persistent.user) {
        await store.dispatch('fetchCurrentUnits');
        await store.dispatch(`container/${ContainerActions.FETCH}`);
        await store.dispatch(`dashboard/${DashboardActions.FETCH}`);
        await store.dispatch(`eventManagement/${EventActions.ENABLE_ALARM_LISTENER}`);
        await store.dispatch(`eventManagement/${EventActions.FETCH_ALL}`);
    }
})();
const NOT_AUTHORIZED = 401;
const LOGIN_FAILURE = 417;
let isSessionExpired;
axios.interceptors.response.use((response) => {
    isSessionExpired = false;
    return response;
}, async (error) => {
    if (error.response) {
        if (error.response.status === NOT_AUTHORIZED) {
            store.dispatch('clearState');
            if (!isSessionExpired) {
                await void router.push({ name: 'login' });
                isSessionExpired = true;
                Toast.createToast(i18n.t('toast.sessionexpired'), 'danger');
            }
        }
        else if (error.response.status === LOGIN_FAILURE) {
            Toast.createToast(i18n.t('toast.invalidcredentials'), 'danger');
        }
    }
    else {
        Toast.createToast(error.message, 'danger');
    }
    return Promise.reject(error);
});
