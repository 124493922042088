import moment from 'moment';
import axios from 'axios';
import chunk from 'lodash/chunk';
import { debug } from '@wisionmonorepo/api-client-v1/../../../config.js';
export const MAX_REQUEST_ENTRIES = 4000;
export var HTTPMethod;
(function (HTTPMethod) {
    HTTPMethod[HTTPMethod["GET"] = 0] = "GET";
    HTTPMethod[HTTPMethod["POST"] = 1] = "POST";
})(HTTPMethod || (HTTPMethod = {}));
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const formatDate = (timestamp) => moment(timestamp).format(DATE_FORMAT);
export const isResult = (obj) => Array.isArray(obj) && obj.length === 1 && isResultObject(obj[0]);
const isResultObject = (obj) => typeof obj === 'object' && obj !== null && 'Result' in obj;
const isEmpty = (obj) => Array.isArray(obj) && obj.length === 1 && isEmptyObject(obj[0]);
export const isEmptyObject = (obj) => typeof obj === 'object' && obj !== null && !Object.keys(obj).length;
export const get = async (endpoint, params) => {
    if (debug)
        console.info(`GET ${endpoint}: ${JSON.stringify(params)}`);
    let response;
    try {
        response = await axios.get('/api/' + endpoint, { params });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }
    catch (err) {
        const message = `API [${endpoint}] ${err.message || err}`;
        throw new Error(message);
    }
    const data = response.data;
    // Handle common errors
    if (data === '') {
        const message = `API [${endpoint}] returned empty string`;
        throw new Error(message);
    }
    return data;
};
export const post = async (endpoint, args) => {
    if (debug)
        console.info(`POST ${endpoint}`); // Don't leak credentials
    let response;
    try {
        response = await axios.post('/api/' + endpoint, args);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }
    catch (err) {
        const message = `API [${endpoint}] ${err.message || err}`;
        throw new Error(message);
    }
    const data = response.data;
    // Handle common errors
    if (data === '') {
        const message = `API [${endpoint}] returned empty string`;
        throw new Error(message);
    }
    return data;
};
// Returns a list or an empty array
export const getList = async (endpoint, params, method = HTTPMethod.GET) => {
    const methodFunction = method === HTTPMethod.GET ? get : post;
    const data = await methodFunction(endpoint, params);
    if (isEmpty(data)) {
        return [];
    }
    if (isResult(data)) {
        const message = `API [${endpoint}] error message - ${data[0].Result}`;
        throw new Error(message);
    }
    return data;
};
// Request array and divide the requests in chunks based on parameter ID values
export const getChunkedList = async (endpoint, params, ids, idKey, reducer = (responses) => responses.flat(1), // Default reducer flattens the array
chunkSize = MAX_REQUEST_ENTRIES, method = HTTPMethod.POST) => {
    if (!ids.length || ids.length < MAX_REQUEST_ENTRIES) {
        return getList(endpoint, params, method);
    }
    const chunks = chunk(ids, chunkSize);
    const promises = [];
    for (let i = 0; i < chunks.length; i++) {
        const chunkParams = { ...params }; // Shallow copy
        // Pass the current chunked entries as a comma separated string for each iteration
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        chunkParams[idKey] = chunks[i].join(',');
        promises.push(getList(endpoint, chunkParams, method));
    }
    return Promise.all(promises)
        .then((collected) => reducer(collected));
};
// Returns a single object or throws an error
export const getSingle = async (endpoint, params, method = HTTPMethod.GET) => {
    const data = await getList(endpoint, params, method);
    if (!data.length) {
        const message = 'Not found';
        throw new Error(message);
    }
    if (data.length > 1) {
        const message = `Expected a single item, but ${data.length} was returned by the API`;
        throw new Error(message);
    }
    return data[0];
};
