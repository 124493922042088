import { formatDate, get, getChunkedList, getList, getSingle, HTTPMethod, isEmptyObject, isResult, post, } from './util';
import { endpoints } from './endpoints';
export const login = async (username, password) => {
    const params = {
        username,
        password,
    };
    try {
        return await post(endpoints.AuthLogin, params);
    }
    catch {
        throw new Error('Authentication failed');
    }
};
export const logout = async () => {
    await get(endpoints.AuthLogout, {});
};
export const loginAccess = async () => {
    return await getSingle(endpoints.LoginAccess, {});
};
export const getAlarms = async ({ unitIds = [], customerIds = [], startDate = new Date('2010-01-01 00:00'), rows = 500, }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
        UnitIDs: unitIds.join(','),
        Rows: rows,
        StartDate: formatDate(startDate),
    };
    return getChunkedList(endpoints.GetAlarms, params, unitIds, 'UnitIDs');
};
export const getUnitsByPos = async ({ unitIds = [], customerIds = [], distanceKm = 100000, rows = 10000, unitGroupIds = [] }) => {
    const params = {
        UnitIDs: unitIds.join(','),
        CustomerIDs: customerIds.join(','),
        Distance: distanceKm,
        UnitGroups: unitGroupIds.join(','),
        Rows: rows,
    };
    return getList(endpoints.GetUnitsByPos, params);
};
export const getUnitGroups = async ({ customerId, }) => {
    const params = {
        CustomerID: customerId,
    };
    return getList(endpoints.GetUnitGroups, params, HTTPMethod.POST);
};
export const getUnitsInfo = async ({ unitIds }) => {
    const params = {
        UnitIDs: unitIds.join(','),
        Rows: unitIds.length,
    };
    return getList(endpoints.GetUnitsInfo, params);
};
export const getUnitType = async ({ customerIds = [] }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
    };
    return getList(endpoints.GetUnitType, params);
};
export const getUnitsShowValue = async ({ customerIds = [], unitIds = [], }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
        UnitIDs: unitIds.join(','),
    };
    return getList(endpoints.GetUnitsShowValue, params);
};
export const getUnitsData = async ({ columns, lastValue, startDate = new Date('2010-01-01 00:00'), // LastValue without a Date doesn't work
unitId, }) => {
    const params = {
        Columns: columns,
        StartDate: formatDate(startDate),
        UnitID: unitId,
        ...(lastValue && { LastValue: true }),
    };
    return get(endpoints.GetUnitsData, params);
};
export const getUnitsDataCSV = async ({ columns, lastValue, startDate = new Date('2010-01-01 00:00'), // LastValue without a Date doesn't work
unitId, }) => {
    const params = {
        Columns: columns,
        StartDate: formatDate(startDate),
        UnitID: unitId,
        ...(lastValue && { LastValue: true }),
    };
    return get(endpoints.GetUnitsDataCSV, params);
};
export const getRadarDates = async ({ unitId, channelId, startDate, stopDate, }) => {
    const params = {
        StartDate: formatDate(startDate),
        ChannelID: channelId,
        UnitID: unitId,
        ...(stopDate && { StopDate: formatDate(stopDate) }),
    };
    return getList(endpoints.GetRadarDates, params);
};
export const getRadar = async ({ channelId, customerId, radarDate, unitId, }) => {
    const params = {
        ChannelID: channelId,
        CustomerID: customerId,
        RadarDate: formatDate(radarDate),
        UnitID: unitId,
    };
    return await getSingle(endpoints.GetRadar, params);
};
export const editMark = async ({ unitId, customerId, channelId, markerId, markerData }) => {
    const params = {
        ChannelID: channelId,
        CustomerID: customerId,
        MarkerID: markerId,
        UnitID: unitId,
        JSonData: JSON.stringify(markerData)
    };
    const response = await post(endpoints.EditMark, params);
    return response[0];
};
export const deleteMark = async ({ unitId, customerId, channelId, markerId }) => {
    const params = {
        ChannelID: channelId,
        CustomerID: customerId,
        MarkerID: markerId,
        UnitID: unitId,
    };
    const response = await get(endpoints.DeleteMark, params);
    return response[0];
};
export const getAllMarks = async ({ channelId, unitId, }) => {
    const params = {
        ChannelID: channelId,
        UnitID: unitId,
    };
    const response = await get(endpoints.GetAllMarks, params);
    if (isResult(response)) {
        return {
            ChannelID: channelId,
            Marker: [],
            UnitID: unitId,
        };
    }
    const data = response[0];
    if (isEmptyObject(data)) {
        throw new Error('Not found');
    }
    return data;
};
export const getKPIAlarms = async ({ customerIds = [], unitIds = [], }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
        UnitIDs: unitIds.join(','),
    };
    // Accumulate the number of alarms for each date
    const reducer = (responses) => responses.reduce((acc, current) => current.map((response) => {
        const found = acc.find(v => v.AlarmDate === response.AlarmDate);
        if (found) {
            return {
                AlarmDate: response.AlarmDate,
                Count: response.Count + found.Count,
            };
        }
        else {
            return response;
        }
    }, []));
    return getChunkedList(endpoints.GetKPIAlarms, params, unitIds, 'UnitIDs', reducer);
};
export const getKPIBattery = async ({ customerIds = [], unitIds = [], }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
        UnitIDs: unitIds.join(','),
    };
    // Accumulate the number of units for each battery level
    const reducer = (responses) => responses.reduce((acc, current) => current.map((response) => {
        const found = acc.find(v => v.DataBatteryVoltage == response.DataBatteryVoltage && v.Status == response.Status);
        if (found) {
            const prevCount = found.D[0].NoOffUnits || 0;
            const currCount = response.D[0].NoOffUnits || 0;
            return {
                DataBatteryVoltage: response.DataBatteryVoltage,
                Status: response.Status,
                D: [{ NoOffUnits: prevCount + currCount }],
            };
        }
        else {
            return response;
        }
    }), []);
    return getChunkedList(endpoints.GetKPIBattery, params, unitIds, 'UnitIDs', reducer);
};
export const getKPISwitch = async ({ customerIds = [], unitIds = [], }) => {
    const params = {
        CustomerIDs: customerIds.join(','),
        UnitIDs: unitIds.join(','),
    };
    return getChunkedList(endpoints.GetKPISwitch, params, unitIds, 'UnitIDs');
};
export const getGISData = async ({ customerId, }) => {
    const params = {
        CustomerID: customerId,
    };
    return get(endpoints.GetGISData, params);
};
export const getReportParkingUsage = async ({ startDate, startTime = '8:00', stopDate, stopTime = '17:00', unitIds, }) => {
    const params = {
        StartDate: formatDate(startDate),
        StartDay: startTime,
        StopDate: formatDate(stopDate),
        StopDay: stopTime,
        UnitIDs: unitIds.join(','),
    };
    return await getSingle(endpoints.GetReportParkingUsage, params);
};
export const getWeather = async ({ latitude, longitude, days = 5, }) => {
    const params = {
        lat: latitude,
        lon: longitude,
        days,
    };
    return get(endpoints.GetWeather, params);
};
export const unitAction = async ({ action, customerId, sequence, unitId, unitSerial, }) => {
    const params = {
        Action: action.toUpperCase(),
        CustomerID: customerId,
        RunAndWait: 1,
        SequenceNumber: sequence,
        UnitID: unitId,
        UnitSerial: unitSerial,
    };
    const data = await post(endpoints.UnitAction, params);
    if (!data.length) {
        throw new Error('No data returned from the API');
    }
    if (data.length > 1) {
        throw new Error(`Expected a single item, but ${data.length} was returned by the API`);
    }
    return data[0];
};
export const getUserData = async ({ id, type, getDefault, }) => {
    const params = {
        ...(id && { ValueID: id }),
        ...(type && { ValueType: type }),
        ...(getDefault && { Default: getDefault ? 'Y' : 'N' }),
    };
    return getList(endpoints.GetUserData, params);
};
export const setUserData = async ({ id, data, type, asDefault, serialize = true, }) => {
    const params = {
        ValueID: id,
        JSonData: serialize ? JSON.stringify(data) : data,
        ...(type && { ValueType: type }),
        ...(asDefault && { Default: asDefault ? 'Y' : 'N' }),
    };
    const response = await post(endpoints.SetUserData, params);
    if (!response.length) {
        throw new Error('No data returned from the API');
    }
    if (response.length > 1) {
        throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
    }
    return response[0];
};
export const deleteUserData = async ({ id, type, }) => {
    const params = {
        ValueID: id,
        ...(type && { ValueType: type }),
    };
    const response = await get(endpoints.DeleteUserData, params);
    if (!response.length) {
        throw new Error('No data returned from the API');
    }
    if (response.length > 1) {
        throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
    }
    return response[0];
};
export const getChannel = async ({ channelId, unitId, customerId, }) => {
    const params = {
        ChannelID: channelId,
        UnitID: unitId,
        CustomerID: customerId
    };
    return await getSingle(endpoints.GetChannel, params);
};
export const editChannel = async ({ unitId, customerId, channelId, columns, }) => {
    const params = {
        ChannelID: channelId,
        CustomerID: customerId,
        UnitID: unitId,
        Columns: columns
    };
    const response = await post(endpoints.EditChannel, params);
    return response[0];
};
export const setRelay = async ({ unitId, dataValue, }) => {
    const params = {
        UnitID: unitId,
        DataValue: dataValue.map(data => data ? '1' : '0').join(''),
    };
    const response = await get(endpoints.SetRelay, params);
    if (!response.length) {
        throw new Error('No data returned from the API');
    }
    if (response.length > 1) {
        throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
    }
    return response[0];
};
