import Vue from 'vue';
import { getDefaultState } from '@/core/store';
export default {
    CLEAR_STATE(state) {
        Object.assign(state, getDefaultState());
    },
    SET_ACTIVE_LANGUAGE(state, language) {
        state.persistent.activeLanguage = language;
    },
    SET_DATE_INTERVAL(state, dateInterval) {
        state.persistent.dateInterval = dateInterval;
    },
    SET_MQTT_CLIENT(state, mqttClient) {
        state.transient.mqttClient = mqttClient;
    },
    SET_PERMISSIONS(state, permissions) {
        state.persistent.permissions = permissions;
    },
    SET_UNIT(state, unit) {
        state.persistent.unitDetails[unit.UnitID] = unit;
    },
    SET_UNITS(state, units) {
        state.persistent.units = units;
    },
    SET_UNIT_OPTIONS(state, unitOptions) {
        unitOptions.forEach((unitOption) => {
            state.persistent.unitOptions[unitOption.UnitID] = unitOption;
        });
    },
    SET_UNIT_TYPES(state, unitTypes) {
        state.persistent.unitTypes = unitTypes;
    },
    SET_USER(state, user) {
        state.persistent.user = user;
        state.persistent.selectedCustomers = user.Customers;
    },
    SET_WEATHER(state, weather) {
        state.persistent.weather[weather.id] = weather;
    },
    SET_SELECTED_CUSTOMERS(state, customers) {
        state.persistent.selectedCustomers = customers;
    },
    SET_TEXT_SEARCH(state, { id, textSearch }) {
        Vue.set(state.transient.textSearch, id, textSearch);
    },
    SET_FILTER_SETTINGS(state, { id, filterSettings }) {
        Vue.set(state.persistent.filterSettings, id, filterSettings);
    },
    REMOVE_FILTER_SETTINGS(state, id) {
        Vue.delete(state.persistent.filterSettings, id);
    },
    SET_SELECTED_UNIT_GROUPS(state, unitGroups) {
        state.persistent.selectedUnitGroups = unitGroups;
    },
};
