import { alarmStatusFilter, parkingUnitFilter } from '@/core/filter';
import { appendLinkGroup, DEFAULT_LINK_GROUP_ID, DEFAULT_LINK_GROUPS } from '@/core/models/linkGroup';
import clone from 'just-clone';
import { nanoid } from 'nanoid';
export const GRID_COL_BREAKPOINTS = { md: 12, sm: 6, xs: 2 };
export const emptyDashboard = {
    name: 'Default',
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    models: {},
    layouts: {
        'md': [],
        'sm': [],
        'xs': [],
    },
    widgets: {},
};
export const staticDashboards = {
    'overview': {
        name: 'nav.overview',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 0, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'status' },
                { x: 2, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'temp' },
                { x: 4, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'battery' },
                { x: 0, y: 5, w: 2, h: 6, mW: 2, mH: 3, i: 'alarm' },
                { x: 2, y: 5, w: 4, h: 6, mW: 2, mH: 3, i: 'weather' },
                { x: 6, y: 11, w: 6, h: 11, mW: 2, mH: 3, i: 'unitmap' },
            ],
            sm: [
                { x: 0, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'status' },
                { x: 2, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'temp' },
                { x: 4, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'battery' },
                { x: 0, y: 5, w: 2, h: 6, mW: 2, mH: 3, i: 'alarm' },
                { x: 2, y: 5, w: 4, h: 6, mW: 2, mH: 3, i: 'weather' },
                { x: 0, y: 11, w: 6, h: 11, mW: 2, mH: 3, i: 'unitmap' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'status' },
                { x: 1, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'alarm' },
                { x: 0, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'battery' },
                { x: 1, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'temp' },
                { x: 0, y: 12, w: 1, h: 3, mW: 1, mH: 3, i: 'weather' },
                { x: 1, y: 12, w: 1, h: 3, mW: 1, mH: 3, i: 'unitmap' },
            ]
        },
        widgets: {
            status: { component: 'StatusWidget', group: 'default' },
            alarm: { component: 'AlarmWidget', group: 'default' },
            battery: { component: 'BatteryWidget', group: 'default' },
            temp: { component: 'TempWidget', group: 'default' },
            weather: { component: 'WeatherWidget', group: 'default' },
            unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
        },
    },
    'units': {
        name: 'nav.units',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 0, y: 0, w: 7, h: 12, mW: 2, mH: 3, i: 'unitlist' },
                { x: 7, y: 0, w: 5, h: 12, mW: 2, mH: 3, i: 'unitmap' },
            ],
            sm: [
                { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'unitlist' },
                { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'unitmap' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'unitlist' },
                { x: 1, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'unitmap' },
            ]
        },
        widgets: {
            unitlist: { component: 'UnitListWidget', group: 'default', props: { multiSelect: true } },
            unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
        },
    },
    'alarms': {
        name: 'nav.alarmlist',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 0, y: 0, w: 8, h: 12, mW: 2, mH: 3, i: 'unitalarmlist' },
                { x: 8, y: 0, w: 4, h: 12, mW: 2, mH: 3, i: 'alarmmap' },
            ],
            sm: [
                { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'unitalarmlist' },
                { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'alarmmap' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'unitalarmlist' },
                { x: 1, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'alarmmap' },
            ]
        },
        widgets: {
            unitalarmlist: { component: 'UnitAlarmWidget', group: 'default' },
            alarmmap: {
                component: 'MapWidget',
                group: 'default',
                props: {
                    type: 'alarms',
                    filter: alarmStatusFilter,
                },
                name: 'widgets.alarmmap.name'
            },
        },
    },
    'map': {
        name: 'nav.map',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 6, y: 0, w: 6, h: 12, mW: 2, mH: 3, i: 'chart' },
                { x: 0, y: 0, w: 6, h: 12, mW: 2, mH: 3, i: 'unitmap' },
            ],
            sm: [
                { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'unitmap' },
                { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'chart' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'unitmap' },
                { x: 1, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'chart' },
            ]
        },
        widgets: {
            chart: { component: 'ChartWidget', group: 'default' },
            unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
        },
    },
    'telemetry': {
        name: 'nav.data',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 4, y: 0, w: 8, h: 12, mW: 2, mH: 3, i: 'chart' },
                { x: 0, y: 0, w: 4, h: 12, mW: 2, mH: 3, i: 'unitlist' },
            ],
            sm: [
                { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'chart' },
                { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'unitlist' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'chart' },
                { x: 1, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'unitlist' },
            ]
        },
        widgets: {
            chart: { component: 'ChartWidget', group: 'default' },
            unitlist: { component: 'UnitListWidget', group: 'default' },
        }
    },
    'parking': {
        name: 'nav.parking',
        unitGroups: [],
        linkGroups: DEFAULT_LINK_GROUPS,
        models: {},
        layouts: {
            md: [
                { x: 0, y: 0, w: 12, h: 5, mW: 2, mH: 3, i: 'stats' },
                { x: 0, y: 5, w: 6, h: 8, mW: 2, mH: 3, i: 'parkinglist' },
                { x: 6, y: 5, w: 6, h: 8, mW: 2, mH: 3, i: 'parkingmap' },
            ],
            sm: [
                { x: 0, y: 0, w: 6, h: 5, mW: 2, mH: 3, i: 'stats' },
                { x: 0, y: 4, w: 6, h: 8, mW: 2, mH: 3, i: 'parkinglist' },
                { x: 0, y: 13, w: 6, h: 6, mW: 2, mH: 3, i: 'parkingmap' },
            ],
            xs: [
                { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'stats' },
                { x: 1, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'parkinglist' },
                { x: 0, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'parkingmap' },
            ]
        },
        widgets: {
            stats: { component: 'ParkingStatisticsWidget', group: 'default' },
            parkinglist: { component: 'ParkingListWidget', group: 'default' },
            parkingmap: {
                component: 'MapWidget',
                group: 'default',
                props: {
                    filter: parkingUnitFilter,
                },
                name: 'widgets.parkingmap.name'
            },
        },
    }
};
/**
 *  Delete all references to widget in dashboard models
 */
export const deleteWidgetReferences = (dashboard, widgetId) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const models = Object.entries(dashboard.models)
        .reduce((models, [linkGroup, linkValue]) => linkValue.updatedBy === widgetId ? models : Object.assign(models, { [linkGroup]: linkValue }), {});
    return {
        ...dashboard,
        models,
    };
};
/**
 *  Delete widget from dashboard
 */
export const deleteWidget = (dashboard, widgetId) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const layouts = clone(dashboard.layouts);
    const widgets = clone(dashboard.widgets);
    for (const breakPointName of Object.keys(GRID_COL_BREAKPOINTS)) {
        const indexToDelete = layouts[breakPointName].map(item => item.i).indexOf(widgetId);
        if (indexToDelete >= 0) {
            layouts[breakPointName].splice(indexToDelete, 1);
        }
    }
    if (widgets[widgetId]) {
        delete widgets[widgetId];
    }
    const models = deleteWidgetReferences(dashboard, widgetId).models;
    return {
        ...dashboard,
        widgets,
        layouts,
        models,
    };
};
/**
 *  Add widget to dashboard
 */
export const addWidget = (dashboard, widget) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const layouts = clone(dashboard.layouts);
    // Making widget id unique to have multiple instance
    const breakpoint = Object.keys(GRID_COL_BREAKPOINTS)[0];
    let id = widget.layouts[breakpoint].i + nanoid(10);
    while (dashboard.widgets[id]) {
        id = widget.layouts[breakpoint].i + nanoid(10);
    }
    for (const breakPointName of Object.keys(GRID_COL_BREAKPOINTS)) {
        // Check and add breakpoints to layouts
        if (!layouts[breakPointName]) {
            layouts[breakPointName] = [];
        }
        const breakpointLayout = {
            x: (dashboard.layouts[breakPointName].length * widget.layouts[breakPointName].w)
                % (GRID_COL_BREAKPOINTS[breakPointName]),
            y: dashboard.layouts[breakPointName].reduce((height, widget) => (height + widget.h), 0)
                + GRID_COL_BREAKPOINTS[breakPointName],
            w: widget.layouts[breakPointName].w,
            h: widget.layouts[breakPointName].h,
            mH: widget.layouts[breakPointName].mH,
            mW: widget.layouts[breakPointName].mW,
            i: id
        };
        layouts[breakPointName] = layouts[breakPointName].concat([breakpointLayout]);
    }
    const widgetConfiguration = {
        name: widget.name,
        component: widget.component,
        group: DEFAULT_LINK_GROUP_ID,
        props: widget.props
    };
    return {
        ...dashboard,
        widgets: {
            ...dashboard.widgets,
            [id]: widgetConfiguration,
        },
        layouts,
    };
};
/**
 *  Add link group to dashboard
 */
export const addLinkGroup = (dashboard, name, color) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const linkGroups = appendLinkGroup(dashboard.linkGroups, name, color);
    return {
        ...dashboard,
        linkGroups,
    };
};
/**
 *  Remove link group from dashboard
 */
export const deleteLinkGroup = (dashboard, groupId) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    // Use destructuring syntax to immutable remove a link group
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { [groupId]: value, ...remainingLinkGroups } = dashboard.linkGroups;
    const widgets = clone(dashboard.widgets);
    // Remove link in all widgets
    Object.entries(widgets).forEach(([widgetId, widget]) => {
        if (widget.group === groupId) {
            widgets[widgetId] = { ...widget, group: undefined };
        }
    });
    return {
        ...dashboard,
        linkGroups: remainingLinkGroups,
        widgets,
    };
};
/**
 *  Link widget to specific link value
 */
export const linkWidgetValue = (dashboard, widgetId, value) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const newDashboard = unlinkWidgetGroup(dashboard, widgetId);
    return {
        ...dashboard,
        models: { ...newDashboard.models, [widgetId]: value },
        widgets: dashboard.widgets,
    };
};
/**
 *  Link widget to link group
 */
export const linkWidgetGroup = (dashboard, widgetId, linkGroup) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    if (!dashboard.linkGroups[linkGroup])
        throw new Error('Link group does not exist');
    const widget = dashboard.widgets[widgetId];
    const models = deleteWidgetReferences(dashboard, widgetId).models;
    return {
        ...dashboard,
        models,
        widgets: {
            ...dashboard.widgets,
            [widgetId]: {
                ...widget,
                group: linkGroup,
            }
        }
    };
};
/**
 *  Unlink widget
 */
export const unlinkWidgetGroup = (dashboard, widgetId) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const widget = dashboard.widgets[widgetId];
    const models = deleteWidgetReferences(dashboard, widgetId).models;
    return {
        ...dashboard,
        models,
        widgets: {
            ...dashboard.widgets,
            [widgetId]: {
                ...widget,
                group: undefined,
            }
        }
    };
};
/**
 *  Emit model value
 */
export const emitModelValue = (dashboard, widgetId, value) => {
    if (!dashboard)
        throw new Error('Invalid dashboard parameter');
    const modelKey = dashboard.widgets[widgetId]?.group || widgetId;
    const updatedLinkValue = { ...dashboard.models[modelKey], ...value, updatedBy: widgetId };
    return {
        ...dashboard,
        models: { ...dashboard.models, [modelKey]: updatedLinkValue },
    };
};
