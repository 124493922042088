/**
 * @overview Event data model
 */
import { nanoid } from 'nanoid';
import { EntityKind } from '@/core/models/dbEntity';
import { i18n } from '@/core/i18n';
import { criticalAlarmTypes } from '@wisionmonorepo/core-device-support/src/enums';
export var EventType;
(function (EventType) {
    EventType["System"] = "system";
    EventType["Alarm"] = "alarm";
})(EventType || (EventType = {}));
export var EventCategory;
(function (EventCategory) {
    EventCategory["Notice"] = "notice";
    EventCategory["Warning"] = "warning";
    EventCategory["PreAlert"] = "pre-alert";
    EventCategory["Alert"] = "alert";
    EventCategory["Critical"] = "critical";
})(EventCategory || (EventCategory = {}));
export const modelVersion = '2';
/**
 * Create alarm event model from alarm API response
 */
export const createAlarmEvent = (alarm, unit, groups) => {
    const id = nanoid();
    const type = EventType.Alarm;
    const alarmId = createAlarmId(alarm);
    const created = new Date();
    const alarmName = i18n.t(`alarmtypes.${alarm.AlarmType}`);
    const title = `${i18n.t('status.alarm')} ${alarmName}`;
    const category = criticalAlarmTypes.includes(alarm.AlarmType) ?
        EventCategory.Alert :
        EventCategory.Notice;
    const address = unit?.UnitLocation || undefined;
    const location = unit?.UnitGPSLongitude && unit?.UnitGPSLatitude ?
        { latitude: unit?.UnitGPSLatitude, longitude: unit?.UnitGPSLongitude } :
        undefined;
    const position = { location, address };
    return {
        id,
        created,
        version: modelVersion,
        kind: EntityKind.Event,
        type,
        title,
        position,
        category,
        unitId: unit.UnitID,
        unitName: unit.UnitName,
        unitType: unit.UnitType,
        alarmId,
        alarm,
        groups,
    };
};
/**
 * Migrate previous model versions to current
 */
export const migrateEventRecord = (record, version) => {
    // Check if records exist that is not up to date
    if (!Object.values(record).map((event) => event.version).find(v => v !== version)) {
        return record;
    }
    return Object.entries(record).reduce((acc, [id, event]) => {
        if (event.version === '1') {
            return {
                ...acc,
                [id]: {
                    ...event,
                    version: '2',
                    category: EventCategory.Notice, // Add needed category property
                }
            };
        }
        else {
            return {
                ...acc,
                [id]: event,
            };
        }
    }, {});
};
/**
 * Create alarm ID from alarm API response
 */
export const createAlarmId = (alarm) => `alarm|${alarm.UnitID}|${alarm.ChannelID}|${alarm.AlarmType}|${alarm.AlarmEventTime}`;
/**
 * Validate event model
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateEvent = (event) => {
    return !!event &&
        event.kind === EntityKind.Event &&
        !!event.created &&
        !!event.id && typeof event.id === 'string' &&
        !!event.type && typeof event.type === 'string';
};
/**
 * Validate event database record
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateEventRecord = (data) => {
    if (!data)
        return false;
    if (typeof data !== 'object')
        return false;
    const event = Object.values(data)?.[0];
    return validateEvent(event);
};
