export const UNDEFINED = 0;
export const X1 = 1;
export const X2 = 2;
export const X1_G = 3;
export const X4 = 4;
export const A1 = 6;
export const X1_B = 7;
export const X1_GB = 8;
export const A1_G = 9;
export const A1_B = 10;
export const A1_GB = 11;
export const X5 = 12;
export const A1E = 13;
export const A1E_G = 14;
export const A1E_B = 15;
export const A1E_GB = 16;
export const X1E = 17;
export const X1E_G = 18;
export const X1E_B = 19;
export const X1E_GB = 20;
export const X1L = 21;
export const X1L_G = 22;
export const X1L_B = 23;
export const X1L_GB = 24;
export const X3 = 25;
export const X3S = 26;
export const XPM = 27;
export const X6 = 28;
export const FA1 = 29;
export const FA1_G = 30;
export const FA1_B = 31;
export const FA1_GB = 32;
export const CNL1 = 33;
export const CNL1_G = 34;
export const CNL1_B = 35;
export const CNL1_GB = 36;
export const CNL2 = 37;
export const CNL2_G = 38;
export const CNL2_B = 39;
export const CNL2_GB = 40;
export const AWG = 41;
export const DLT = 200;
export const ALS = 201;
export const ALA = 202;
export const ALT = 203;
export const ALL = 204;
export const ALM = 205;
export const ALC = 206;
export const ALP = 208;
export const ALD = 209;
export const TWV = 221;
export const TWT = 222;
export const MCV = 223;
export const SEW = 226;
export const WIV = 228;
export const W1E = 224;
// Undocumented IoT devices
export const ELS = 207;
export const ELR = 210;
export const ELE = 211;
export const EEL = 212;
export const E2H = 213;
export const EM7 = 214;
export const ELM = 215;
export const SH = 216;
export const SWL = 217;
export const SLT = 217;
export const LPA = 219;
export const NPS = 225;
export const SCO = 220;
export const CIP = 227;
export const DNIL = 243;
// Measurepoints?
export const MEASUREPOINT_X6 = 128;
export const MEASUREPOINT_XPM = 127;
export const MEASUREPOINT_X3S = 126;
export const MEASUREPOINT_X3 = 125;
export const MEASUREPOINT_X5 = 112;
export const MEASUREPOINT_X4 = 104;
const IOT_UNIT_RANGE_START = 200;
export const RADAR_UNITS = [
    X4,
    X5,
    X3,
    X3S,
    XPM,
    X6,
    CNL2,
    CNL2_G,
    CNL2_B,
    CNL2_GB,
    MEASUREPOINT_X4,
    MEASUREPOINT_X5,
    MEASUREPOINT_X3,
    MEASUREPOINT_X3S,
    MEASUREPOINT_XPM,
    MEASUREPOINT_X6,
];
export const IOT_UNITS = [
    ALA,
    ALC,
    ALD,
    ALL,
    ALM,
    ALS,
    ALT,
    ALP,
    DLT,
    E2H,
    ELR,
    ELE,
    EEL,
    ELM,
    ELS,
    SH,
    SWL,
    SLT,
    SCO,
    EM7,
    LPA,
];
export const PARKING_UNITS = [
    LPA,
    NPS,
    CIP,
];
export const LEVEL2_UNITS = [
    X2,
    X3,
    X3S,
    X4,
    X5,
    X6,
    XPM,
];
export const RELAY_UNITS = [
    DNIL,
];
export const UNIT_TYPE_NAME = {
    [UNDEFINED]: 'Undefined',
    [X1]: 'X1',
    [X2]: 'X2',
    [X1_G]: 'X1-G',
    [X4]: 'X4',
    [A1]: 'A1',
    [X1_B]: 'X1-B',
    [X1_GB]: 'X1-GB',
    [A1_G]: 'A1-G',
    [A1_B]: 'A1-B',
    [A1_GB]: 'A1-GB',
    [X5]: 'X5',
    [A1E]: 'A1E',
    [A1E_G]: 'A1E-G',
    [A1E_B]: 'A1E-B',
    [A1E_GB]: 'A1E-GB',
    [X1E]: 'X1E',
    [X1E_G]: 'X1E-G',
    [X1E_B]: 'X1E-B',
    [X1E_GB]: 'X1E-GB',
    [X1L]: 'X1L',
    [X1L_G]: 'X1L-G',
    [X1L_B]: 'X1L-B',
    [X1L_GB]: 'X1L-GB',
    [X3]: 'X3',
    [X3S]: 'X3S',
    [XPM]: 'XPM',
    [X6]: 'X6',
    [FA1]: 'FA1',
    [FA1_G]: 'FA1-G',
    [FA1_B]: 'FA1-B',
    [FA1_GB]: 'FA1-GB',
    [CNL1]: 'CNL1',
    [CNL1_G]: 'CNL1-G',
    [CNL1_B]: 'CNL1-B',
    [CNL1_GB]: 'CNL1-GB',
    [CNL2]: 'CNL2',
    [CNL2_G]: 'CNL2-G',
    [CNL2_B]: 'CNL2-B',
    [CNL2_GB]: 'CNL2-GB',
    [DLT]: 'DLT Dragino Temp Humidity',
    [AWG]: 'AWG',
    [ALS]: 'ALS Adeunis Switch',
    [ALA]: 'ALA Adeunis Analog',
    [ALT]: 'ALT Adeunis Temp-3',
    [ALL]: 'ALL Elsys CO2',
    [ALM]: 'ALM Adeunis Motion',
    [ALC]: 'ALC Adeunis Comfort',
    [ELS]: 'ELS Elsys Sound',
    [ALP]: 'ALP Adeunis Pulse',
    [ALD]: 'ALD Adeunis Delta-P',
    [ELR]: 'ELR Elsys Ers',
    [ELE]: 'ELE Elsys Eye',
    [EEL]: 'EEL Elsys Elt lite',
    [E2H]: 'E2H Elsys Elt-2-HP',
    [EM7]: 'EM7 Elsys Elt-2-MB7389',
    [ELM]: 'ELM Elsys EMS',
    [SH]: 'SH Sensative H',
    [SWL]: 'SWL Sensative WL',
    [SLT]: 'SLT Senlab-T',
    [LPA]: 'LPA Libelium',
    [SCO]: 'SCO Senseair CO2',
    [TWV]: 'TWV TWGT Valve',
    [TWT]: 'TWT TWGT Temp',
    [MCV]: 'MCV mClimate Vicki Temp',
    [W1E]: 'W1E Axioma w1e',
    [NPS]: 'NPS Nwave Parking Snow',
    [SEW]: 'SEW Sensoneo Waste',
    [CIP]: 'CIP Cicicom Parking',
    [WIV]: 'WIV Wise2',
};
export const isParking = (unitType) => PARKING_UNITS.includes(unitType);
export const isParkingUnit = (unit) => isParking(unit.UnitType);
export const isRadar = (unitType) => RADAR_UNITS.includes(unitType);
export const isIot = (unitType) => unitType >= IOT_UNIT_RANGE_START;
export const isLevel2 = (unitType) => LEVEL2_UNITS.includes(unitType);
